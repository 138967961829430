/* eslint-disable */

import React, { Component } from "react";
import "./Banner.css";
import moment from "moment";
import {
  zonelistAccoData,
  renderMovieTitle,
  ActivityZoneData,
  PackageZoneData,
  TransferZoneData,
  TransferSourceZoneData,
  TransferDestinationZoneData,
  TransferAirportType,
  TransferFerryType,
  TransferCityType,
  TransferToAirportCityType,
  TransferCityToAirportType,
  TransferCityToCityType,
  LiveAboardZoneData,
} from "./axios";
import Autosuggest from "react-autosuggest";
import BannerTab from "./BannerTab";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuroSign,
  faMapMarkedAlt,
  faHotel,
  faPlane,
  faShip,
  faLocationArrow
} from "@fortawesome/free-solid-svg-icons";
import {
  RangeDatePicker,
  SingleDatePicker,
} from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";

import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import useCollapse from "react-collapsed";
// import { LinkedCalendar } from 'rb-datepicker';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-daterangepicker/daterangepicker.css';

const ListNumber = [
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" },
  { id: "5" },
  { id: "6" },
  { id: "7" },
  { id: "8" },
  { id: "9" },
  { id: "10" },
  { id: "11" },
  { id: "12" },
  { id: "13" },
];

const theme = {
  container: "react-autosuggest__container",
  containerOpen: "react-autosuggest__container--open",
  input: "react-autosuggest__input",
  inputOpen: "react-autosuggest__input--open",
  inputFocused: "react-autosuggest__input--focused",
  suggestionsContainer: "react-autosuggest__suggestions-container",
  suggestionsContainerOpen: "react-autosuggest__suggestions-container--open",
  suggestionsList: "react-autosuggest__suggestions-list",
  suggestion: "react-autosuggest__suggestion",
  suggestionFirst: "react-autosuggest__suggestion--first",
  suggestionHighlighted: "react-autosuggest__suggestion--highlighted",
  sectionContainer: "react-autosuggest__section-container",
  sectionContainerFirst: "react-autosuggest__section-container--first",
  sectionTitle: "react-autosuggest__section-title",
};

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      isOpen: false,
      dropdownVisible: false,
      selected: "Accommodations",
      searchInput: "",
      itemsZone: [],
      itemsZoneActivity: [],
      itemsZonePackage: [],
      itemsZoneTransfer: [],
      itemsZoneSourceTransfer: [],
      itemsZoneDestinationTransfer: [],
      itemsZoneAirportTransfer: [],
      itemsZoneToAirportTransfer: [],
      itemsZoneAirportToCityTransfer: [],
      itemsZoneFerryTransfer: [],
      itemsZoneFerryToTransfer: [],
      itemsZoneCityTransfer: [],
      itemsZoneLiveAboard: [],
      error: null,
      val: "",
      value: "",
      valueActivity: "",
      valuePackage: "",
      valueTransfer: "",
      valueSourceTransfer: "",
      valueDestinationTransfer: "",
      valueAirportTransfer: "",
      valueToAirportTransfer: "",
      valueAirportToCityTransfer: "",
      valueFerryTransfer: "",
      valueFerryToTransfer: "",
      valueCityTransfer: "",
      selectTransferType: "1",
      selectPickupType: 1,
      valueLiveAboard: "",
      suggestions: [],
      suggestionsActivity: [],
      suggestionsPackage: [],
      suggestionsTransfer: [],
      suggestionsSourceTransfer: [],
      suggestionsDestinationTransfer: [],
      suggestionsAirportTransfer: [],
      suggestionsToAirportTransfer: [],
      suggestionsAirportToCityTransfer: [],
      suggestionsFerryTransfer: [],
      suggestionsFerryToTransfer: [],
      suggestionsCityTransfer: [],
      suggestionsLiveAboard: [],
      showResults: false,
      showResultsActivity: false,
      showResultsPackage: false,
      showResultsTransfer: false,
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      showResultsLiveAboard: false,
      defaultZoneval: "Mahe Island",
      defaultZonevalActivity: "Mahe Island",
      defaultZonevalPackage: "Mahe Island",
      defaultZonevalTransfer: "Seychelles",
      defaultZonevalSourceTransfer: "Victoria",
      defaultZonevalDestinationTransfer: "Anse Royale",
      defaultZonevalAirportTransfer: "Seychelles International Airport, Mahe Island",
      defaultZonevalToAirportTransfer: "Seychelles International Airport, Mahe Island",
      defaultZonevalAirportToCityTransfer: "Victoria",
      defaultZonevalFerryTransfer: "Mahe Jetty Port",
      defaultZonevalFerryToTransfer: "Praslin Island Port",
      defaultZonevalCityTransfer: "Victoria",
      defaultZonevalLiveAboard: "Seychelles",
      selectActivityDay: moment(new Date())
        .add(20, "day")
        .format("ddd, D MMM y"),
      selectActivityDayNew: new Date(),
      selectPackageDay: moment(new Date())
        .add(20, "day")
        .format("ddd, D MMM y"),
      selectPackageDayNew: new Date(),
      selectTransferDay: moment(new Date())
        .add(20, "day")
        .format("ddd, D MMM y"),
      selectTransferDayNew: new Date(),
      selectLiveAboardDay: moment(new Date())
        .add(20, "day")
        .format("ddd, D MMM y"),
      selectLiveAboardDayNew: new Date(),
      checkinShow: false,
      checkinShowActivity: false,
      checkinShowPackage: false,
      checkinShowTransfer: false,
      checkinShowLiveAboard: false,
      cats: [
        { adultCounter: 1, childCounter: 0, roomCounter: 1, isExpanded: true },
      ],
      showPickupType: true,
      showFromAirPort: true,
      showToAirPort: false,
      ShowTransportType: false,
      showFerryType: false,
    };
  }

  // onDatesChange = ({ startDate, endDate }) => {
  //     console.log(({ startDate, endDate }));
  // }
  query = ["Mahe island"];

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  increment = () => {
    if (this.state.adultCounter < 15) {
      this.setState({ adultCounter: this.state.adultCounter + 1 });
    }
  };

  decrement = () => {
    if (this.state.adultCounter > 0) {
      this.setState({ adultCounter: this.state.adultCounter - 1 });
    }
  };

  incrementChild = () => {
    if (this.state.adultCounter == 0) {
      this.setState({ adultCounter: this.state.adultCounter + 1 });
    }
    if (this.state.childCounter < 6) {
      this.setState({ childCounter: this.state.childCounter + 1 });
    }
  };

  decrementChild = () => {
    if (this.state.childCounter > 0) {
      this.setState({ childCounter: this.state.childCounter - 1 });
    }
  };

  incrementRoom = () => {
    if (this.state.roomCounter < 10) {
      this.setState({ roomCounter: this.state.roomCounter + 1 });
    }
  };

  decrementRoom = () => {
    if (this.state.roomCounter > 0) {
      this.setState({ roomCounter: this.state.roomCounter - 1 });
    }
  };
  componentDidMount() {
    zonelistAccoData().then(
      (data) => {
        // console.log(data);
        this.setState({
          itemsZone: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    ActivityZoneData().then(
      (data) => {
        this.setState({
          itemsZoneActivity: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    PackageZoneData().then(
      (data) => {
        this.setState({
          itemsZonePackage: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    // TransferZoneData().then(
    //     (data) => {
    //         this.setState({

    //             itemsZoneTransfer: data,
    //         });
    //     },
    //     (error) => {
    //         this.setState({
    //             error
    //         });
    //     }
    // );
    TransferSourceZoneData().then(
      (data) => {
        //console.log(data);
        this.setState({
          itemsZoneSourceTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    //City To City
    TransferCityToCityType(this.state.defaultZonevalSourceTransfer).then(
      (data) => {
        this.setState({
          itemsZoneDestinationTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    // TransferDestinationZoneData().then(
    //     (data) => {
    //         this.setState({

    //             itemsZoneDestinationTransfer: data,
    //         });
    //     },
    //     (error) => {
    //         this.setState({
    //             error
    //         });
    //     }
    // );

    TransferAirportType().then(
      (data) => {
        this.setState({
          itemsZoneAirportTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

   
    // Airport To City
    TransferToAirportCityType(this.state.defaultZonevalAirportTransfer).then(
      (data) => {
         console.log(data);
        this.setState({
          itemsZoneAirportToCityTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    //City To Airport
    TransferCityToAirportType(this.state.defaultZonevalCityTransfer).then(
        (data) => {
            this.setState({

              itemsZoneToAirportTransfer: data,
            });
        },
        (error) => {
            this.setState({
                error
            });
        }
    );

    TransferFerryType().then(
      (data) => {
        this.setState({
          itemsZoneFerryTransfer: data,
        });
        this.setState({
          itemsZoneFerryToTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    TransferCityType().then(
      (data) => {
        this.setState({
          itemsZoneCityTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    //City To City
    // TransferCityToCityType().then(
    //     (data) => {
    //         this.setState({

    //             itemsZoneCityTransfer: data,
    //         });
    //     },
    //     (error) => {
    //         this.setState({
    //             error
    //         });
    //     }
    // );

    LiveAboardZoneData().then(
      (data) => {
        this.setState({
          itemsZoneLiveAboard: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    // let dateString = new Date(); // date string
    // let actualDate = new Date(dateString); // convert to actual date
    // let newDate = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate() + 1);

    // this.setState({ endDate: newDate });
    let dateString = new Date(); // date string
    let actualDate = new Date(dateString); // convert to actual date
    let startnewDate = new Date(
      actualDate.getFullYear(),
      actualDate.getMonth(),
      actualDate.getDate() + 20
    );
    let newDate = new Date(
      actualDate.getFullYear(),
      actualDate.getMonth(),
      actualDate.getDate() + 21
    );

    this.setState({ startDate: startnewDate });
    this.setState({ endDate: newDate });
    this.setState({ selectActivityDayNew: startnewDate });
    this.setState({ selectPackageDayNew: startnewDate });
    this.setState({ selectTransferDayNew: startnewDate });
    this.setState({ selectLiveAboardDayNew: startnewDate });
  }

  componentWillUnmount() {
    // document.removeEventListener('click', this.globalClickListener)
    window.removeEventListener("click", this.globalClickListener);
  }
  globalClickListener = (nativeEvent) => {
    this.setState(
      {
        dropdownVisible: false,
        showResults: false,
        checkinShow: false,
        showResultsActivity: false,
        checkinShowActivity: false,
        showResultsPackage: false,
        checkinShowPackage: false,
        showResultsTransfer: false,
        showResultsSourceTransfer: false,
        showResultsDestinationTransfer: false,
        showResultsAirportTransfer: false,
        showResultsToAirportTransfer: false,
        showResultsAirportToCityTransfer: false,
        showResultsFerryTransfer: false,
        showResultsFerryToTransfer: false,
        showResultsCityTransfer: false,
        checkinShowTransfer: false,
        showResultsLiveAboard: false,
        checkinShowLiveAboard: false,
      },
      () => {
        //console.log('global click')
        // console.log(this.state.showResults)
        // document.removeEventListener('click', this.globalClickListener)
        window.removeEventListener("click", this.globalClickListener);
      }
    );
  };
  toggleDropdown = (syntheticEvent) => {
    // console.log('toggle dropdown')
    this.setState({ checkinShow: false, showResults: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ dropdownVisible: !prevState.dropdownVisible }),
      () => {
        if (this.state.dropdownVisible) {
          // console.log(this.state.dropdownVisible);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    // console.log(this.state.dropdownVisible);
  };

  checkinandcheckout = (syntheticEvent) => {
    // console.log('toggle dropdown')
    this.setState({ dropdownVisible: false, showResults: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ checkinShow: !prevState.checkinShow }),
      () => {
        if (this.state.checkinShow) {
          //console.log(this.state.checkinShow);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    // console.log(this.state.dropdownVisible);
  };

  checkinandcheckoutActivity = (syntheticEvent) => {
    // console.log('toggle dropdown')

    this.setState({ showResultsActivity: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ checkinShowActivity: !prevState.checkinShowActivity }),
      () => {
        if (this.state.checkinShowActivity) {
          //console.log(this.state.checkinShowActivity);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    // console.log(this.state.dropdownVisible);
  };

  checkinandcheckoutPackage = (syntheticEvent) => {
    // console.log('toggle dropdown')
    this.setState({ showResultsPackage: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ checkinShowPackage: !prevState.checkinShowPackage }),
      () => {
        if (this.state.checkinShowPackage) {
          // console.log(this.state.checkinShowPackage);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    // console.log(this.state.dropdownVisible);
  };

  checkinandcheckoutTransfer = (syntheticEvent) => {
    // console.log('toggle dropdown')
    this.setState({
      showResultsTransfer: false,
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ checkinShowTransfer: !prevState.checkinShowTransfer }),
      () => {
        if (this.state.checkinShowTransfer) {
          //console.log(this.state.checkinShowTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    // console.log(this.state.dropdownVisible);
  };

  checkinandcheckoutLiveAboard = (syntheticEvent) => {
    // console.log('toggle dropdown')
    this.setState({ showResultsLiveAboard: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        checkinShowLiveAboard: !prevState.checkinShowLiveAboard,
      }),
      () => {
        if (this.state.checkinShowLiveAboard) {
          //console.log(this.state.checkinShowLiveAboard);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    // console.log(this.state.dropdownVisible);
  };
  handleBodyClick = (event) => {
    // console.log('body click');
    // console.log(event);
    event.stopPropagation();
  };

  addRoom = (e) => {
    const catLength = this.state.cats.length;
    var i = 0;
    for (i = 0; i <= catLength - 1; i++) {
      let cats = [...this.state.cats];
      cats[i]["isExpanded"] = false;
      this.setState({
        cats: cats,
      });
    }

    this.setState((prevState) => ({
      cats: [
        ...prevState.cats,
        { adultCounter: 1, childCounter: 0, roomCounter: 1, isExpanded: true },
      ],
    }));
  };

  handleEditRoomClick = (index) => {
    const catLength = this.state.cats.length;
    var i = 0;
    for (i = 0; i <= catLength - 1; i++) {
      let cats = [...this.state.cats];
      cats[i]["isExpanded"] = false;
      this.setState({
        cats: cats,
      });
    }

    let cater = [...this.state.cats];
    cater[index]["isExpanded"] = true;
    this.setState({
      cats: cater,
    });
  };

  applyRoom = (e) => {
    this.setState(
      { dropdownVisible: false, showResults: false, checkinShow: false },
      () => {
        // console.log('global click')
        // console.log(this.state.showResults)
        // document.removeEventListener('click', this.globalClickListener)
        window.removeEventListener("click", this.globalClickListener);
      }
    );
  };

  handleInputChange = (e, index) => {
    const { adultCounter, value } = e;
    let cats = [...this.state.cats];

    cats[index]["adultCounter"] = e;
    this.setState({
      cats: cats,
    });
  };

  handleInputChangeChild = (e, index) => {
    let cats = [...this.state.cats];
    cats[index]["childCounter"] = e;
    this.setState({
      cats: cats,
    });
  };
  handleRemoveClick = (index) => {
    let cats = [...this.state.cats];
    cats.splice(index, 1);
    this.setState({
      cats: cats,
    });
  };

  renderDropdownMenu() {
    return (
      <div className="roomsGuests" onClick={this.handleBodyClick}>
        {this.state.cats.map((val, idx) => {
          if (idx <= 2) {
            return (
              <div className="roomsGuestsTop" key={idx}>
                <div className="addRoomRowhs">
                  <div className="addRoomLeft">
                    <p className="darkText font16 latoBlack capText appendBottom10">
                      ROOM {idx + 1}
                    </p>
                  </div>
                  <div className="addRoomRight">
                    {val.isExpanded == true ? (
                      <div className="addRooomDetails">
                        <p className="hsFlexspaceBetween appendBottom10">
                          <span className="latoBold font12 grayText">
                            Adults (12y +){" "}
                          </span>
                        </p>
                        <ul className="guestCounter font12 darkText">
                          {ListNumber.map((item, index) => (
                            <li
                              className={
                                this.state.cats[idx].adultCounter == item.id
                                  ? "selected"
                                  : ""
                              }
                              key={index}
                              onClick={() =>
                                this.handleInputChange(item.id, idx)
                              }
                            >
                              {item.id}
                            </li>
                          ))}
                        </ul>
                        <div>
                          <p className="latoBold font12 grayText appendBottom10">
                            Children (1y - 12y )
                          </p>
                          <ul className="guestCounter font12 darkText">
                            {ListNumber.slice(0, 6).map((item, index) => (
                              <li
                                className={
                                  this.state.cats[idx].childCounter == index
                                    ? "selected"
                                    : ""
                                }
                                key={index}
                                onClick={() =>
                                  this.handleInputChangeChild(index, idx)
                                }
                              >
                                {index}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="editRoom">
                        <p className="hdhsFlex end">
                          <span className="font16 blackText">
                            <span className="latoBlack">
                              {this.state.cats[idx].adultCounter}
                            </span>{" "}
                            Adults,
                            <span className="latoBlack">
                              {" "}
                              {this.state.cats[idx].childCounter}
                            </span>{" "}
                            Children
                          </span>
                          <span
                            className="font12 appendLeft10 editRoomClass"
                            onClick={() => this.handleEditRoomClick(idx)}
                          >
                            Edit{" "}
                          </span>
                        </p>
                        <span
                          className="font12 editRoomClass"
                          key={idx}
                          onClick={() => this.handleRemoveClick(idx)}
                        >
                          Remove
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
        <div className="roomsGuestsBottom">
          {this.state.cats.length <= 2 && (
            <span className="btnAddRoom" onClick={this.addRoom}>
              + Add Another Room{" "}
            </span>
          )}
          <span className="submit-button" onClick={this.applyRoom}>
            Apply
          </span>
        </div>
      </div>
    );
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? this.state.itemsZone
      : this.state.itemsZone.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  getSuggestionsActivity = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneActivity.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  getSuggestionsPackage = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZonePackage.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // Source = (value) => {
  //     const inputValue = value.trim().toLowerCase();
  //     const inputLength = inputValue.length;

  //     return inputLength === 0 ? [] : this.state.itemsZoneTransfer.filter(lang =>
  //         lang.Name.toLowerCase().slice(0, inputLength) === inputValue
  //     );
  // };

  getSuggestionsSourceTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    // console.log(inputValue);
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneSourceTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsDestinationTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneDestinationTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  getSuggestionsAirportTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneAirportTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsToAirportTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneToAirportTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsAirportToCityTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneAirportToCityTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  getSuggestionsFerryTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneFerryTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  getSuggestionsFerryToTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneFerryToTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };
  getSuggestionsCityTransfer = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneCityTransfer.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  getSuggestionsLiveAboard = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZoneLiveAboard.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.Name;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion, { query, isHighlighted }) => (
   
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
      {suggestion.AreaType == 'City' || suggestion.AreaType == 'Country'  ?(<div className="locationImageLocation">
          <img
          src="images/locationacco.png"
          className="locationImageContent"
          />
      </div>):(<FontAwesomeIcon icon={faHotel} className="appendLeft5 appendRight12 textLeft"
          style={{ fontSize: "16px", marginRight:10, color: "#ffaa52" }}/>)}

        <div className="flexOne">
          <p className="locusLabel"> {suggestion.Name}</p>
        </div>
        <span
          className="width60 font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight"
          style={{ width: "100px", padding: "0px" }}
        >
          {suggestion.AreaType}
        </span>
      </div>
    </div>
  );

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
  
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        {/* <ul role="listbox" class="react-autosuggest__suggestions-list">
                    <li role="option" id="react-autowhatever-1-section-0-item-0" aria-selected="false" class="react-autosuggest__suggestion react-autosuggest__suggestion--first" data-section-index="0" data-suggestion-index="0">
                        <div class="font14 darkText  noChild  cl_disabled">
                            <div class="spaceBetween makeFlex hrtlCenter ">
                                <div>
                                    <p class="locusLabel appendBottom5">Seychelles</p>
                                </div>
                                <span class="font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight autoSuggestHtlResultType">Country</span>
                            </div>
                        </div>
                    </li>
                    <li role="option" id="react-autowhatever-1-section-0-item-1" aria-selected="false" class="react-autosuggest__suggestion" data-section-index="0" data-suggestion-index="1">
                        <div class="font14 darkText  childElem  clickable">
                            <div class="spaceBetween makeFlex hrtlCenter regionLists">
                                <div>
                                    <p class="locusLabel appendBottom5">Mahe, Seychelles</p>
                                </div>
                                <span class="font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight autoSuggestHtlResultType">City</span>
                            </div>
                        </div>
                    </li>
                    <li role="option" id="react-autowhatever-1-section-0-item-2" aria-selected="false" class="react-autosuggest__suggestion" data-section-index="0" data-suggestion-index="2">
                        <div class="font14 darkText  childElem  clickable">
                            <div class="spaceBetween makeFlex hrtlCenter regionLists">
                                <div>
                                    <p class="locusLabel appendBottom5">Praslin, Seychelles</p>
                                </div>
                                <span class="font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight autoSuggestHtlResultType">City</span>
                            </div>
                        </div>
                    </li>
                    <li role="option" id="react-autowhatever-1-section-0-item-3" aria-selected="false" class="react-autosuggest__suggestion" data-section-index="0" data-suggestion-index="3">
                        <div class="font14 darkText  childElem  clickable">
                            <div class="spaceBetween makeFlex hrtlCenter regionLists">
                                <div>
                                    <p class="locusLabel appendBottom5">Desroches, Seychelles</p>
                                </div>
                                <span class="font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight autoSuggestHtlResultType">City</span>
                            </div>
                        </div>
                    </li>
                    <li role="option" id="react-autowhatever-1-section-0-item-4" aria-selected="false" class="react-autosuggest__suggestion" data-section-index="0" data-suggestion-index="4">
                        <div class="font14 darkText  childElem  clickable">
                            <div class="spaceBetween makeFlex hrtlCenter regionLists">
                                <div>
                                    <p class="locusLabel appendBottom5">Silhouette Island, Seychelles</p>
                                </div>
                                <span class="font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight autoSuggestHtlResultType">City</span>
                            </div>
                        </div>
                    </li>
                    <li role="option" id="react-autowhatever-1-section-0-item-5" aria-selected="false" class="react-autosuggest__suggestion" data-section-index="0" data-suggestion-index="5">
                        <div class="font14 darkText  childElem  clickable">
                            <div class="spaceBetween makeFlex hrtlCenter regionLists">
                                <div>
                                    <p class="locusLabel appendBottom5">La Digue, Seychelles</p>
                                </div>
                                <span class="font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight autoSuggestHtlResultType">City</span>
                            </div>
                        </div>
                    </li>
                </ul> */}

       
        {query.length === 0 ? (
            <div>
                {/* <p class="hsw_sectionTitle font12 latoBlack greyText">Popular</p> */}
        <ul role="listbox" class="react-autosuggest__suggestions-list">
       {/* <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div class="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionAccomodation("Seychelles")}>
              <div class="hsFlexspaceBetween hsFlex hrtlCenter">
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
              <div className="flexOne">
                <p className="locusLabel">Seychelles</p>
                </div>
              </div>
            </div>
          </li>*/}
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div class="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionAccomodation("Mahe Island")}>
              <div class="hsFlexspaceBetween hsFlex hrtlCenter">
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
              <div className="flexOne">
                <p className="locusLabel">Mahe Island</p>
                </div>
              </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div class="font14 darkText  noChild  clickable"  onClick={() => this.handleChangeSuggetionAccomodation("Praslin Island")}>
              <div class="hsFlexspaceBetween hsFlex hrtlCenter">
              <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
              <div className="flexOne">
                <p className="locusLabel">Praslin Island</p>
                </div>
              </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div class="font14 darkText  noChild  clickable"  onClick={() => this.handleChangeSuggetionAccomodation("La Digue Island")}>
              <div class="hsFlexspaceBetween hsFlex hrtlCenter">
              <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
              <div className="flexOne">
                <p className="locusLabel">La Digue Island</p>
                </div>
              </div>
            </div>
          </li>
          
        </ul></div>
         ) : (
            <div> {children}</div>
          )}
      
      </div>
    );
  };
  handleChangeSuggetionAccomodation = (newValue) => {
    this.setState({
      value: newValue,
      defaultZoneval: newValue,
    });
    this.setState({
        showResults: false,
      });
  };
  onChange = (event, { newValue }) => {
    //  console.log(newValue);
    this.setState({
      value: newValue,
      defaultZoneval: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = () => {
    this.setState({
      showResults: false,
    });
  };

  showZonelistdiv = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResults: !prevState.showResults }),
      () => {
        if (this.state.showResults) {
          console.log(this.state.showResults);
          // dropdownVisible: false, showResults: false, checkinShow: false,
          this.setState({
            dropdownVisible: false,
            checkinShow: false,
          });
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };

  onSuggestionsFetchRequestedActivity = ({ value }) => {
    this.setState({
      suggestionsActivity: this.getSuggestionsActivity(value),
    });
  };

  onSuggestionsFetchRequestedPackage = ({ value }) => {
    this.setState({
      suggestionsPackage: this.getSuggestionsPackage(value),
    });
  };

  // onSuggestionsFetchRequestedTransfer = ({ value }) => {
  //     this.setState({
  //         suggestionsTransfer: this.Source(value)
  //     });
  // };
  onSuggestionsFetchRequestedSourceTransfer = ({ value }) => {
    this.setState({
      suggestionsSourceTransfer: this.getSuggestionsSourceTransfer(value),
    });
  };
  onSuggestionsFetchRequestedDestinationTransfer = ({ value }) => {
    this.setState({
      suggestionsDestinationTransfer: this.getSuggestionsDestinationTransfer(
        value
      ),
    });
  };

  onSuggestionsFetchRequestedAirportTransfer = ({ value }) => {
    this.setState({
      suggestionsAirportTransfer: this.getSuggestionsAirportTransfer(value),
    });
  };
  onSuggestionsFetchRequestedToAirportTransfer = ({ value }) => {
    this.setState({
      suggestionsToAirportTransfer: this.getSuggestionsToAirportTransfer(value),
    });
  };
  onSuggestionsFetchRequestedAirportToCityTransfer = ({ value }) => {
    this.setState({
      suggestionsAirportToCityTransfer: this.getSuggestionsAirportToCityTransfer(
        value
      ),
    });
  };

  onSuggestionsFetchRequestedFerryTransfer = ({ value }) => {
    this.setState({
      suggestionsFerryTransfer: this.getSuggestionsFerryTransfer(value),
    });
  };
  onSuggestionsFetchRequestedFerryToTransfer = ({ value }) => {
    this.setState({
      suggestionsFerryToTransfer: this.getSuggestionsFerryToTransfer(value),
    });
  };
  onSuggestionsFetchRequestedCityTransfer = ({ value }) => {
    this.setState({
      suggestionsCityTransfer: this.getSuggestionsCityTransfer(value),
    });
  };

  onSuggestionsFetchRequestedLiveAboard = ({ value }) => {
    this.setState({
      suggestionsLiveAboard: this.getSuggestionsLiveAboard(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequestedActivity = () => {
    this.setState({
      suggestionsActivity: [],
    });
  };

  onSuggestionsClearRequestedPackage = () => {
    this.setState({
      suggestionsPackage: [],
    });
  };

  // onSuggestionsClearRequestedTransfer = () => {
  //     this.setState({
  //         suggestionsTransfer: []
  //     });
  // };

  onSuggestionsClearRequestedSourceTransfer = () => {
    this.setState({
      suggestionsSourceTransfer: [],
    });
  };
  onSuggestionsClearRequestedDestinationTransfer = () => {
    this.setState({
      suggestionsDestinationTransfer: [],
    });
  };

  onSuggestionsClearRequestedAirportTransfer = () => {
    this.setState({
      suggestionsAirportTransfer: [],
    });
  };
  onSuggestionsClearRequestedToAirportTransfer = () => {
    this.setState({
      suggestionsToAirportTransfer: [],
    });
  };
  onSuggestionsClearRequestedAirportToCityTransfer = () => {
    this.setState({
      suggestionsAirportToCityTransfer: [],
    });
  };

  onSuggestionsClearRequestedFerryTransfer = () => {
    this.setState({
      suggestionsFerryTransfer: [],
    });
  };
  onSuggestionsClearRequestedFerryToTransfer = () => {
    this.setState({
      suggestionsFerryToTransfer: [],
    });
  };
  onSuggestionsClearRequestedCityTransfer = () => {
    this.setState({
      suggestionsCityTransfer: [],
    });
  };

  onSuggestionsClearRequestedLiveAboard = () => {
    this.setState({
      suggestionsLiveAboard: [],
    });
  };

  onSuggestionSelectedActivity = () => {
    this.setState({
      showResultsActivity: false,
    });
  };

  onSuggestionSelectedPackage = () => {
    this.setState({
      showResultsPackage: false,
    });
  };

  // onSuggestionSelectedTransfer = () => {
  //     this.setState({
  //         showResultsTransfer: false
  //     });
  // }

  onSuggestionSelectedSourceTransfer = () => {
    this.setState({
      showResultsSourceTransfer: false,
    });
  };
  onSuggestionSelectedDestinationTransfer = () => {
    this.setState({
      showResultsDestinationTransfer: false,
    });
  };

  onSuggestionSelectedAirportTransfer = () => {
    this.setState({
      showResultsAirportTransfer: false,
    });
  };
  onSuggestionSelectedToAirportTransfer = () => {
    this.setState({
      showResultsToAirportTransfer: false,
    });
  };
  onSuggestionSelectedAirportToCityTransfer = () => {
    this.setState({
      showResultsAirportToCityTransfer: false,
    });
  };

  onSuggestionSelectedFerryTransfer = () => {
    this.setState({
      showResultsFerryTransfer: false,
    });
  };
  onSuggestionSelectedFerryToTransfer = () => {
    this.setState({
      showResultsFerryToTransfer: false,
    });
  };
  onSuggestionSelectedCityTransfer = () => {
    this.setState({
      showResultsCityTransfer: false,
    });
  };

  onSuggestionSelectedLiveAboard = () => {
    this.setState({
      showResultsLiveAboard: false,
    });
  };

  getSuggestionValueActivity = (suggestion) => suggestion.Name;

  getSuggestionValuePackage = (suggestion) => suggestion.Name;

  // getSuggestionValueTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueSourceTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueDestinationTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueAirportTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueToAirportTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueAirportToCityTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueFerryTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueFerryToTransfer = (suggestion) => suggestion.Name;
  getSuggestionValueCityTransfer = (suggestion) => suggestion.Name;

  getSuggestionValueLiveAboard = (suggestion) => suggestion.Name;

  // Use your imagination to render suggestions.
  renderSuggestionActivity = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImageLocation">
          <img
          src="images/locationacco.png"
          className="locationImageContent"
          />
        </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );

  renderSuggestionPackage = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {suggestion.LocationImage != "nolocation" ? (
            <div className="locationImageLocation">
            <img
            src="images/locationacco.png"
            className="locationImageContent"
            />
        </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );

  // renderSuggestionTransfer = (suggestion, { query, isHighlighted }) => (
  //     <div className="font14 darkText  noChild  clickable"><div className="hsFlexspaceBetween hsFlex hrtlCenter ">
  //         {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
  //         ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
  //         )}
  //         <div className="flexOne"><p className="locusLabel appendBottom5 fontSizeChange">{suggestion.Name}</p></div></div></div>
  // );

  renderSuggestionSourceTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
            ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
            )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
        <span
          className="width60 font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight"
          style={{ width: "100px", padding: "0px" }}
        >
          {suggestion.AreaType}
        </span>
      </div>
    </div>
  );
  renderSuggestionDestinationTransfer = (
    suggestion,
    { query, isHighlighted }
  ) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
            ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
            )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
        <span
          className="width60 font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight"
          style={{ width: "100px", padding: "0px" }}
        >
          {suggestion.AreaType}
        </span>
      </div>
    </div>
  );
  renderSuggestionAirportTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
            ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
            )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionToAirportTransfer = (
    suggestion,
    { query, isHighlighted }
  ) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
            ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
            )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionAirportToCityTransfer = (
    suggestion,
    { query, isHighlighted }
  ) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
            ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
            )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionFerryTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
            ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
            )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionFerryToTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
            ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
            )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );
  renderSuggestionCityTransfer = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {/* {suggestion.LocationImage != "nolocation" ? (<div className="locationImage"><img src={suggestion.LocationImage} className="locationImageContent" /></div>
            ) : (<div className="tourImage"> <img src={suggestion.Image} className="locationImageContent2" /></div>
            )} */}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );

  renderSuggestionLiveAboard = (suggestion, { query, isHighlighted }) => (
    <div className="font14 darkText  noChild  clickable">
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        {suggestion.LocationImage != "nolocation" ? (
          <div className="locationImageLocation">
          <img
          src="images/locationacco.png"
          className="locationImageContent"
          />
      </div>
        ) : (
          <div className="tourImage">
            {" "}
            <img src={suggestion.Image} className="locationImageContent2" />
          </div>
        )}
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
            {suggestion.Name}
          </p>
        </div>
      </div>
    </div>
  );

  renderSuggestionsContainerActivity = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        {query.length === 0 ? (
            <div>
                {/* <p class="hsw_sectionTitle font12 latoBlack greyText">Popular</p> */}
        <ul role="listbox" class="react-autosuggest__suggestions-list">
      {/*  <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionActivity("Seychelles")}>
                <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
                
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
                
                    <div className="flexOne">
                    <p className="locusLabel appendBottom5 fontSizeChange">
                    Seychelles
                    </p>
                    </div>
                    </div>
            </div>
          </li>*/}
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionActivity("Mahe Island")}>
                <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
                
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
                
                    <div className="flexOne">
                    <p className="locusLabel appendBottom5 fontSizeChange">
                    Mahe Island
                    </p>
                    </div>
                    </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionActivity("Praslin Island")}>
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
      
            <div className="locationImageLocation">
                <img
                src="images/locationacco.png"
                className="locationImageContent"
                />
            </div>
       
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
          Praslin Island
          </p>
        </div>
      </div>
      </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionActivity("La Digue Island")}>
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
      
        <div className="locationImageLocation">
            <img
            src="images/locationacco.png"
            className="locationImageContent"
            />
        </div>
       
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
          La Digue Island
          </p>
        </div>
      </div>
    </div>
          </li>
          
        </ul></div>
         ) : (
            <div> {children}</div>
          )}
       
   
      </div>
    );
  };

  renderSuggestionsContainerPackage = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        {query.length === 0 ? (
            <div>
                {/* <p class="hsw_sectionTitle font12 latoBlack greyText">Popular</p> */}
        <ul role="listbox" class="react-autosuggest__suggestions-list">
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionPackage("Mahe Island")}>
                <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
                
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
                
                    <div className="flexOne">
                    <p className="locusLabel appendBottom5 fontSizeChange">
                    Mahe Island
                    </p>
                    </div>
                    </div>
            </div>
          </li>
         { /* <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionPackage("Seychelles")}>
                <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
                
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
                    <div className="flexOne">
                    <p className="locusLabel appendBottom5 fontSizeChange">
                    Seychelles
                    </p>
                    </div>
                    </div>
            </div>
          </li> */}
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionPackage("Praslin Island")}>
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
            <div className="locationImageLocation">
                <img
                src="images/locationacco.png"
                className="locationImageContent"
                />
            </div>
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
          Praslin Island
          </p>
        </div>
      </div>
      </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionPackage("La Digue Island")}>
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
      <div className="locationImageLocation">
          <img
          src="images/locationacco.png"
          className="locationImageContent"
          />
      </div>
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
          La Digue Island
          </p>
        </div>
      </div>
    </div>
          </li>
          
        </ul></div>
         ) : (
            <div> {children}</div>
          )}
      </div>
    );
  };

  // renderSuggestionsContainerTransfer = ({ containerProps, children }) => {
  //     return (
  //         <div {...containerProps}>
  //             <div class="react-autosuggest__section-title"><label class="hsw_sectionTitle font12 latoBlack greyText">SUGGESTIONS </label></div>
  //             <div> {children}</div>

  //         </div>
  //     );
  // }

  renderSuggestionsContainerSourceTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerDestinationTransfer = ({
    containerProps,
    children,
  }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  renderSuggestionsContainerAirportTransfer = ({
    containerProps,
    children,
  }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerToAirportTransfer = ({
    containerProps,
    children,
  }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerAirportToCityTransfer = ({
    containerProps,
    children,
  }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  renderSuggestionsContainerFerryTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };
  renderSuggestionsContainerFerryToTransfer = ({
    containerProps,
    children,
  }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  renderSuggestionsContainerCityTransfer = ({ containerProps, children }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        <div> {children}</div>
      </div>
    );
  };

  renderSuggestionsContainerLiveAboard = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
            SUGGESTIONS{" "}
          </label>
        </div>
        {query.length === 0 ? (
            <div>
                {/* <p class="hsw_sectionTitle font12 latoBlack greyText">Popular</p> */}
        <ul role="listbox" class="react-autosuggest__suggestions-list">
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionLiveAboard("Mahe Island")}>
                <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
                
                    <div className="locationImageLocation">
                        <img
                        src="images/locationacco.png"
                        className="locationImageContent"
                        />
                    </div>
                    <div className="flexOne">
                    <p className="locusLabel appendBottom5 fontSizeChange">
                    Mahe Island
                    </p>
                    </div>
                    </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionLiveAboard("Seychelles")}>
                <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
                
                    <div className="locationImageLocation">
                        <img
                        src="images/locationacco.png"
                        className="locationImageContent"
                        />
                    </div>
                    <div className="flexOne">
                    <p className="locusLabel appendBottom5 fontSizeChange">
                    Seychelles
                    </p>
                    </div>
                    </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionLiveAboard("Praslin Island")}>
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        <div className="locationImageLocation">
            <img
            src="images/locationacco.png"
            className="locationImageContent"
            />
        </div>
       
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
          Praslin Island
          </p>
        </div>
      </div>
      </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div className="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionLiveAboard("La Digue Island")}>
      <div className="hsFlexspaceBetween hsFlex hrtlCenter ">
        <div className="locationImageLocation">
            <img
            src="images/locationacco.png"
            className="locationImageContent"
            />
        </div>
        <div className="flexOne">
          <p className="locusLabel appendBottom5 fontSizeChange">
          La Digue Island
          </p>
        </div>
      </div>
    </div>
          </li>
          
        </ul></div>
         ) : (
            <div> {children}</div>
          )}
        {/* <div> {children}</div> */}
      </div>
    );
  };

  showZonelistdivActivity = (syntheticEvent) => {
    this.setState({ checkinShowActivity: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsActivity: !prevState.showResultsActivity }),
      () => {
        if (this.state.showResultsActivity) {
          // console.log(this.state.showResultsActivity);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );

    this.setState({ valueActivity: "" });
  };

  showZonelistdivPackage = (syntheticEvent) => {
    this.setState({ checkinShowPackage: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResultsPackage: !prevState.showResultsPackage }),
      () => {
        if (this.state.showResultsPackage) {
          //  console.log(this.state.showResultsPackage);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valuePackage: "" });
  };

  // showZonelistdivTransfer = (syntheticEvent) => {

  //     syntheticEvent.stopPropagation();
  //     this.setState(prevState => ({ showResultsTransfer: !prevState.showResultsTransfer }), () => {
  //         if (this.state.showResultsTransfer) {
  //             console.log(this.state.showResultsTransfer);

  //             // document.addEventListener('click', this.globalClickListener)
  //             window.addEventListener('click', this.globalClickListener)
  //         }
  //     })
  //     this.setState({ valueTransfer: '' });
  // }

  showZonelistdivSourceTransfer = (syntheticEvent) => {
    this.setState({
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsSourceTransfer: !prevState.showResultsSourceTransfer,
      }),
      () => {
        if (this.state.showResultsSourceTransfer) {
          //console.log(this.state.showResultsSourceTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueTransfer: "" });
  };

  showZonelistdivDestinationTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsDestinationTransfer: !prevState.showResultsDestinationTransfer,
      }),
      () => {
        if (this.state.showResultsDestinationTransfer) {
          // console.log(this.state.showResultsDestinationTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueTransfer: "" });
  };

  showZonelistdivAirportTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsAirportTransfer: !prevState.showResultsAirportTransfer,
      }),
      () => {
        if (this.state.showResultsAirportTransfer) {
          // console.log(this.state.showResultsAirportTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueTransfer: "" });
  };

  showZonelistdivToAirportTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsToAirportTransfer: !prevState.showResultsToAirportTransfer,
      }),
      () => {
        if (this.state.showResultsToAirportTransfer) {
          // console.log(this.state.showResultsToAirportTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueTransfer: "" });
  };

  showZonelistdivAirportToCityTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsAirportToCityTransfer: !prevState.showResultsAirportToCityTransfer,
      }),
      () => {
        if (this.state.showResultsAirportToCityTransfer) {
          //console.log(this.state.showResultsAirportToCityTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueTransfer: "" });
  };
  showZonelistdivFerryTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryToTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsFerryTransfer: !prevState.showResultsFerryTransfer,
      }),
      () => {
        if (this.state.showResultsFerryTransfer) {
          //  console.log(this.state.showResultsFerryTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueTransfer: "" });
  };
  showZonelistdivFerryToTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsCityTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsFerryToTransfer: !prevState.showResultsFerryToTransfer,
      }),
      () => {
        if (this.state.showResultsFerryToTransfer) {
          // console.log(this.state.showResultsFerryToTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueTransfer: "" });
  };
  showZonelistdivCityTransfer = (syntheticEvent) => {
    this.setState({
      showResultsSourceTransfer: false,
      showResultsDestinationTransfer: false,
      showResultsAirportTransfer: false,
      showResultsToAirportTransfer: false,
      showResultsAirportToCityTransfer: false,
      showResultsFerryTransfer: false,
      showResultsFerryToTransfer: false,
      checkinShowTransfer: false,
    });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsCityTransfer: !prevState.showResultsCityTransfer,
      }),
      () => {
        if (this.state.showResultsCityTransfer) {
          // console.log(this.state.showResultsCityTransfer);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueTransfer: "" });
  };

  showZonelistdivLiveAboard = (syntheticEvent) => {
    this.setState({ checkinShowLiveAboard: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({
        showResultsLiveAboard: !prevState.showResultsLiveAboard,
      }),
      () => {
        if (this.state.showResultsLiveAboard) {
          // console.log(this.state.showResultsLiveAboard);

          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    this.setState({ valueLiveAboard: "" });
  };

  onChangeActivity = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      valueActivity: newValue,
      defaultZonevalActivity: newValue,
    });
  };
  handleChangeSuggetionActivity = (newValue) => {
    this.setState({
    valueActivity: newValue,
    defaultZonevalActivity: newValue,
    });
    this.setState({
        showResultsActivity: false,
      });
  };

  onChangePackage = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      valuePackage: newValue,
      defaultZonevalPackage: newValue,
    });
  };

  handleChangeSuggetionPackage = (newValue) => {
    this.setState({
    valuePackage: newValue,
    defaultZonevalPackage: newValue,
    });
    this.setState({
        showResultsPackage: false,
      });
  };
  // onChangeTransfer = (event, { newValue }) => {
  //     console.log(newValue);
  //     this.setState({
  //         valueTransfer: newValue,
  //         defaultZonevalTransfer: newValue,
  //     });
  // };
  onChangeSourceTransfer = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      itemsZoneDestinationTransfer: [],
    });
    this.setState({
      valueSourceTransfer: newValue,
      defaultZonevalSourceTransfer: newValue,
    });

    //City To City
    TransferCityToCityType(newValue).then(
      (data) => {
        this.setState({
          itemsZoneDestinationTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };
  onChangeDestinationTransfer = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      valueDestinationTransfer: newValue,
      defaultZonevalDestinationTransfer: newValue,
    });
  };

  onChangeAirportTransfer = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      itemsZoneAirportToCityTransfer: [],
    });
    this.setState({
      valueAirportTransfer: newValue,
      defaultZonevalAirportTransfer: newValue,
    });

    // Airport To City
    TransferToAirportCityType(newValue).then(
      (data) => {
       // console.log("ranjeet", data);
        this.setState({
          itemsZoneAirportToCityTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };
  onChangeToAirportTransfer = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      valueToAirportTransfer: newValue,
      defaultZonevalToAirportTransfer: newValue,
    });
  };
  onChangeAirportToCityTransfer = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      valueAirportToCityTransfer: newValue,
      defaultZonevalAirportToCityTransfer: newValue,
    });
  };
  onChangeFerryTransfer = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      valueFerryTransfer: newValue,
      defaultZonevalFerryTransfer: newValue,
    });
  };
  onChangeFerryToTransfer = (event, { newValue }) => {
    //console.log(newValue);
    this.setState({
      valueFerryToTransfer: newValue,
      defaultZonevalFerryToTransfer: newValue,
    });
  };
  onChangeCityTransfer = (event, { newValue }) => {
    this.setState({
      itemsZoneToAirportTransfer: [],
    });
    this.setState({
      valueCityTransfer: newValue,
      defaultZonevalCityTransfer: newValue,
    });

    // City To Airport
    TransferCityToAirportType(newValue).then(
      (data) => {
        // console.log(data);
        this.setState({
          itemsZoneToAirportTransfer: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  handleTransferType = (tranfertype) => {
    this.setState({ selectTransferType: tranfertype.target.value });
    if (tranfertype.target.value == 1) {
      this.setState({ showPickupType: true });
      this.setState({ showFromAirPort: true });
      this.setState({ ShowTransportType: false });
      this.setState({ showFerryType: false });
    } else if (tranfertype.target.value == 3) {
      this.setState({ showFerryType: true });
      this.setState({ showPickupType: false });
      this.setState({ ShowTransportType: false });
      this.setState({ showToAirPort: false });
      this.setState({ showFromAirPort: false });
    } else if (tranfertype.target.value == 2) {
      this.setState({ showPickupType: false });
      this.setState({ ShowTransportType: true });
      this.setState({ showToAirPort: false });
      this.setState({ showFromAirPort: false });
      this.setState({ showFerryType: false });
    } else {
      this.setState({ showPickupType: true });
      this.setState({ showFromAirPort: true });
      this.setState({ ShowTransportType: false });
      this.setState({ showFerryType: false });
    }
  };

  handlePickupType = (pickuptype) => {
    this.setState({ selectPickupType: pickuptype.target.value });
    if (pickuptype.target.value == 1) {
      this.setState({ showFromAirPort: true });
      this.setState({ showToAirPort: false });
      this.setState({ ShowTransportType: false });
    } else if (pickuptype.target.value == 2) {
      this.setState({ showToAirPort: true });
      this.setState({ showFromAirPort: false });
      this.setState({ ShowTransportType: false });
    } else if (pickuptype.target.value == 3) {
      this.setState({ ShowTransportType: true });
    } else {
      this.setState({ showFromAirPort: true });
      this.setState({ showToAirPort: false });
      this.setState({ ShowTransportType: false });
    }
  };

  onChangeLiveAboard = (event, { newValue }) => {
    // console.log(newValue);
    this.setState({
      valueLiveAboard: newValue,
      defaultZonevalLiveAboard: newValue,
    });
  };

  handleChangeSuggetionLiveAboard = (newValue) => {
    this.setState({
    valueLiveAboard: newValue,
    defaultZonevalLiveAboard: newValue,
    });
    this.setState({
        showResultsLiveAboard: false,
      });
  };
  handleActivityDayClick = (day) => {
    this.setState({ selectActivityDayNew: day });
    this.setState({ selectActivityDay: moment(day).format("ddd, D MMM y") });
    this.setState({ checkinShowActivity: false });
  };

  handlePackageDayClick = (day) => {
    //  console.log(day);
    this.setState({ selectPackageDayNew: day });
    this.setState({ selectPackageDay: moment(day).format("ddd, D MMM y") });
    this.setState({ checkinShowPackage: false });
  };

  handleTransferDayClick = (day) => {
    // console.log(day);
    this.setState({ selectTransferDayNew: day });
    this.setState({ selectTransferDay: moment(day).format("ddd, D MMM y") });
    this.setState({ checkinShowTransfer: false });
  };

  handleLiveAboardDayClick = (day) => {
    // console.log(day);
    this.setState({ selectLiveAboardDayNew: day });
    this.setState({ selectLiveAboardDay: moment(day).format("ddd, D MMM yy") });
    this.setState({ checkinShowLiveAboard: false });
  };

  render() {
    //  console.log(this.state.itemsZoneSourceTransfer);
    const menuClass = `panel-dropdown${
      this.state.dropdownVisible ? " active" : ""
    }`;
    const {
      value,
      suggestions,
      valueActivity,
      suggestionsActivity,
      valuePackage,
      suggestionsPackage,
      valueTransfer,
      suggestionsTransfer,
      valueSourceTransfer,
      valueDestinationTransfer,
      valueAirportTransfer,
      valueToAirportTransfer,
      valueAirportToCityTransfer,
      valueFerryTransfer,
      valueFerryToTransfer,
      valueCityTransfer,
      suggestionsSourceTransfer,
      suggestionsDestinationTransfer,
      suggestionsAirportTransfer,
      suggestionsToAirportTransfer,
      suggestionsAirportToCityTransfer,
      suggestionsFerryTransfer,
      suggestionsFerryToTransfer,
      suggestionsCityTransfer,
      valueLiveAboard,
      suggestionsLiveAboard,
    } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Enter City, Location or Property Name",
      type: "search",
      value: value,
      onChange: this.onChange,
    };

    const inputPropsActivity = {
      placeholder: "Enter location or activity name",
      type: "search",
      value: valueActivity,
      onChange: this.onChangeActivity,
    };

    const inputPropsPackage = {
      placeholder: "Enter location or package name",
      type: "search",
      value: valuePackage,
      onChange: this.onChangePackage,
    };

    // const inputPropsTransfer = {
    //     placeholder: 'Enter location',
    //     type: "search",
    //     value: valueTransfer,
    //     onChange: this.onChangeTransfer
    // };
    const inputPropsSourceTransfer = {
      placeholder: "Enter Source Location ",
      type: "search",
      value: valueSourceTransfer,
      onChange: this.onChangeSourceTransfer,
    };
    const inputPropsDestinationTransfer = {
      placeholder: "Enter Destination Location  ",
      type: "search",
      value: valueDestinationTransfer,
      onChange: this.onChangeDestinationTransfer,
    };

    const inputPropsAirportTransfer = {
      placeholder: "Enter Airport Location  ",
      type: "search",
      value: valueAirportTransfer,
      onChange: this.onChangeAirportTransfer,
    };
    const inputPropsToAirportTransfer = {
      placeholder: "Enter Airport Location  ",
      type: "search",
      value: valueToAirportTransfer,
      onChange: this.onChangeToAirportTransfer,
    };
    const inputPropsAirportToCityTransfer = {
      placeholder: "Enter City",
      type: "search",
      value: valueAirportToCityTransfer,
      onChange: this.onChangeAirportToCityTransfer,
    };
    const inputPropsFerryTransfer = {
      placeholder: "Enter Ferry Location  ",
      type: "search",
      value: valueFerryTransfer,
      onChange: this.onChangeFerryTransfer,
    };
    const inputPropsFerryToTransfer = {
      placeholder: "Enter Ferry Location  ",
      type: "search",
      value: valueFerryToTransfer,
      onChange: this.onChangeFerryToTransfer,
    };
    const inputPropsCityTransfer = {
      placeholder: "Enter City Location  ",
      type: "search",
      value: valueCityTransfer,
      onChange: this.onChangeCityTransfer,
    };

    const inputPropsLiveAboard = {
      placeholder: "Enter location or liveaboard name",
      type: "search",
      value: valueLiveAboard,
      onChange: this.onChangeLiveAboard,
    };

    // console.log(this.state.cats);
    //console.log(this.state.endDate);

    return (
      <>
        <BannerTab isSelected={this.props.isSelected === "Accommodations"}>
          <div className="serch-top-bar">
            <div className="row">
              <div className="col-md-12">
                <h6 className="justify-content-center">
                  Book any kind of stay
                </h6>
              </div>
              <div
                className="col-lg-5 col-md-5 col-xs-12 col-sm-12 Allhover"
                onClick={this.showZonelistdiv}
              >
                <div className="focused_div bor">
                  <div className="">
                    <span>City, Location or Property Name</span>
                    <p className="defaultZone">
                      {this.state.defaultZoneval.length >= 60
                        ? `${this.state.defaultZoneval.substring(0, 60)}...`
                        : this.state.defaultZoneval}
                    </p>
                    {this.state.showResults ? (
                      <div
                        className="autocomplete-wrapper"
                        style={{ top: 115 }}
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionSelected={this.onSuggestionSelected}
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainer
                          }
                          renderSuggestion={this.renderSuggestion}
                          inputProps={inputProps}
                          theme={theme}
                          // alwaysRenderSuggestions={true}
                          // highlightFirstSuggestion={true}
                          //shouldRenderSuggestions={true}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-4 col-xs-12 col-sm-12 Allhover "
                onClick={this.checkinandcheckout}
              >
                <div className="bor">
                  <div>
                    <span
                      style={{
                        width: "100%",
                        textAlign: "initial",
                        marginRight: "35%",
                      }}
                    >
                      Check-In
                    </span>
                    <span style={{ width: "100%", textAlign: "initial" }}>
                      Check-Out
                    </span>
                  </div>
                  {/* <p class="blackText font20 code"><span class="font30 lineHeight36 latoBlack">8</span><span style={{ marginTop: 10, marginLeft: 2 }}>  Jun</span><span class="shortYear" style={{ marginTop: 10, marginLeft: 2 }}>21</span></p>
                                    <br /><p class="code">Tuesday</p> */}

                  <RangeDatePicker
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={(startDate, endDate) =>
                      this.setState({ startDate: startDate, endDate: endDate })
                    }
                    minDate={new Date()}
                    maxDate={new Date(2100, 0, 1)}
                    startDatePlaceholder="Add Select Check-In Date"
                    endDatePlaceholder="Add Select Check-Out Date"
                    className="my-own-class-name"
                    startWeekDay="monday"
                  />
                </div>
              </div>

              <div
                className="col-lg-3 col-md-3 col-xs-12 col-sm-12 Allhover"
                onClick={this.toggleDropdown}
              >
                <div className="bor">
                  <div className={menuClass}>
                    <div className="">
                      <span>Room & Guests</span>
                    </div>
                    {this.state.dropdownVisible && this.renderDropdownMenu()}

                    <div className="Guestss" style={{ display: "inline-flex" }}>
                      <p>
                        {this.state.cats.reduce((a, v) => a + v.roomCounter, 0)}
                        <span> Room</span>{" "}
                        {this.state.cats.reduce(
                          (a, v) => a + v.adultCounter * 1 + v.childCounter * 1,
                          0
                        )}
                        <span> Guests</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="sercheng">
            <a
              href={
                "/hotellisting?defaultZoneval=" +
                encodeURIComponent(this.state.defaultZoneval) +
                "&checkin=" +
                encodeURIComponent(this.state.startDate) +
                "&checkout=" +
                encodeURIComponent(this.state.endDate) +
                "&guestCombination=" +
                JSON.stringify(this.state.cats)
              }
              className="btn_searchs"
            >
              SEARCH
            </a>
            {/* <Link to={{
                            pathname: '/hotellisting',
                            state: {
                                defaultZoneval: this.state.defaultZoneval,
                                checkin: this.state.startDate,
                                checkout: this.state.endDate,
                                guestCombination: this.state.cats,
                            }
                        }} className="btn_searchs">Search</Link> */}
          </div>
        </BannerTab>

        <BannerTab
          isSelected={this.props.isSelected === "Attractions & Things to Do"}
        >
          <div className="serch-top-bar">
            <div className="row">
              <div className="col-md-12">
                <h6 className="justify-content-center">
                  Book any kind of activity, tour or daytrip
                </h6>
              </div>
              <div
                className="col-lg-6 col-md-6 col-xs-12 col-sm-12 Allhover"
                onClick={this.showZonelistdivActivity}
              >
                <div className="bor">
                  <span style={{ width: "100%", textAlign: "initial" }}>
                    Location or activity name
                  </span>
                  <p className="defaultZone">
                    {this.state.defaultZonevalActivity.length >= 60
                      ? `${this.state.defaultZonevalActivity.substring(
                          0,
                          60
                        )}...`
                      : this.state.defaultZonevalActivity}
                  </p>
                  {this.state.showResultsActivity ? (
                    <div
                      className="autocomplete-wrapper"
                      style={{ top: 148 }}
                      onClick={this.handleBodyClick}
                    >
                      <Autosuggest
                        suggestions={suggestionsActivity}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequestedActivity
                        }
                        onSuggestionSelected={this.onSuggestionSelectedActivity}
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequestedActivity
                        }
                        getSuggestionValue={this.getSuggestionValueActivity}
                        renderSuggestionsContainer={
                          this.renderSuggestionsContainerActivity
                        }
                        renderSuggestion={this.renderSuggestionActivity}
                        inputProps={inputPropsActivity}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                className="col-lg-6 col-md-6 col-xs-12 col-sm-12 Allhover"
                onClick={this.checkinandcheckoutActivity}
              >
                <div className="bor">
                  <span style={{ width: "100%", textAlign: "initial" }}>
                    Add when you want to go
                  </span>
                  <p className="defaultZone">{this.state.selectActivityDay}</p>
                  <div onClick={this.handleBodyClick}>
                    {this.state.checkinShowActivity && (
                      <DayPicker
                        className="activityDate"
                        onDayClick={this.handleActivityDayClick}
                        selectedDays={this.state.selectActivityDayNew}
                        minDate={new Date()}
                        disabledDays={{ before: new Date() }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sercheng">
            <a
              href={
                "/activitylisting?defaultZonevalActivityVal=" +
                encodeURIComponent(this.state.defaultZonevalActivity) +
                "&dateSelected=" +
                encodeURIComponent(this.state.selectActivityDayNew)
              }
              className="btn_searchs"
            >
              SEARCH
            </a>
          </div>
        </BannerTab>

        <BannerTab isSelected={this.props.isSelected === "Packages"}>
          <div className="serch-top-bar">
            <div className="row">
              <div className="col-md-12">
                <h6 className="justify-content-center">
                  Book any kind of package
                </h6>
              </div>

              <div
                className="col-lg-6 col-md-6 col-xs-12 col-sm-12 Allhover"
                onClick={this.showZonelistdivPackage}
              >
                <div className="bor">
                  <span style={{ width: "100%", textAlign: "initial" }}>
                    Location or package name
                  </span>
                  <p className="defaultZone">
                    {this.state.defaultZonevalPackage.length >= 60
                      ? `${this.state.defaultZonevalPackage.substring(
                          0,
                          60
                        )}...`
                      : this.state.defaultZonevalPackage}
                  </p>
                  {this.state.showResultsPackage ? (
                    <div
                      className="autocomplete-wrapper"
                      style={{ top: 148 }}
                      onClick={this.handleBodyClick}
                    >
                      <Autosuggest
                        suggestions={suggestionsPackage}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequestedPackage
                        }
                        onSuggestionSelected={this.onSuggestionSelectedPackage}
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequestedPackage
                        }
                        getSuggestionValue={this.getSuggestionValuePackage}
                        renderSuggestionsContainer={
                          this.renderSuggestionsContainerPackage
                        }
                        renderSuggestion={this.renderSuggestionPackage}
                        inputProps={inputPropsPackage}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                className="col-lg-6 col-md-6 col-xs-12 col-sm-12 Allhover"
                onClick={this.checkinandcheckoutPackage}
              >
                <div className="bor">
                  <span style={{ width: "100%", textAlign: "initial" }}>
                    Add when you want to go
                  </span>
                  <p className="defaultZone">{this.state.selectPackageDay}</p>
                  <div onClick={this.handleBodyClick}>
                    {this.state.checkinShowPackage && (
                      <DayPicker
                        className="activityDate"
                        onDayClick={this.handlePackageDayClick}
                        selectedDays={this.state.selectPackageDayNew}
                        minDate={new Date()}
                        disabledDays={{ before: new Date() }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sercheng">
            <a
              href={
                "/packagelisting?defaultZonevalPackageVal=" +
                encodeURIComponent(this.state.defaultZonevalPackage) +
                "&dateSelected=" +
                encodeURIComponent(this.state.selectPackageDayNew)
              }
              className="btn_searchs"
            >
              SEARCH
            </a>
          </div>
        </BannerTab>

        {/* <BannerTab isSelected={this.props.isSelected === 'Land & Ferry Transfers'}>
                    <div className="row widgetSection" style={{ marginTop: '0px' }}>
                        <h1>Book any kind of land and ferry transfers</h1>
                        <div className="totalsercheng" style={{ height: 120, marginTop: -30 }}>
                            <div className="col-lg-6 Allhover" style={{ borderRight: '1px solid #c1c1c1', cursor: 'pointer' }} onClick={this.showZonelistdivTransfer}>
                                <div className="focused_div">
                                    <div className="form-group">
                                        <span style={{ width: '100%', textAlign: 'initial' }}>Location</span>
                                        <p className="defaultZone">{
                                            this.state.defaultZonevalTransfer.length >= 25 ? (`${this.state.defaultZonevalTransfer.substring(0, 25)}...`) : (this.state.defaultZonevalTransfer)
                                        }</p>
                                        {this.state.showResultsTransfer ?
                                            <div className="autocomplete-wrapper" style={{ top: 30 }} onClick={this.handleBodyClick}>
                                                <Autosuggest
                                                    suggestions={suggestionsTransfer}
                                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedTransfer}
                                                    onSuggestionSelected={this.onSuggestionSelectedTransfer}
                                                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedTransfer}
                                                    getSuggestionValue={this.getSuggestionValueTransfer}
                                                    renderSuggestionsContainer={this.renderSuggestionsContainerTransfer}
                                                    renderSuggestion={this.renderSuggestionTransfer}
                                                    inputProps={inputPropsTransfer}
                                                />
                                            </div> : null
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-6 Allhover" style={{ paddingTop: '12px' }} onClick={this.checkinandcheckoutTransfer}>
                                <div className="form-group">
                                    <span style={{ width: '100%', textAlign: 'initial' }}>Add when you want to go</span>
                                    <p className="defaultZone">{this.state.selectTransferDay}</p>
                                    <div onClick={this.handleBodyClick}>
                                        {this.state.checkinShowTransfer && <DayPicker className="activityDate" onDayClick={this.handleTransferDayClick} selectedDays={this.state.selectTransferDayNew} />}

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="sercheng">
                        <a href={"/transferlisting?defaultZonevalTransferVal=" + encodeURIComponent(this.state.defaultZonevalTransfer) + "&dateSelected=" + encodeURIComponent(this.state.selectTransferDayNew)} className="btn_searchs">Search
                        </a>
                    </div>
                </BannerTab> */}

        <BannerTab
          isSelected={this.props.isSelected === "Land & Ferry Transfers"}
        >
          <div className="serch-top-bar">
            <div className="row">
              <div className="col-md-12">
                <h6 className="justify-content-center">
                  Book any kind of land and ferry transfers
                </h6>
              </div>

              <div className="col-lg-2 col-md-2 col-xs-12 col-sm-12 Allhover">
                <div className="bor">
                  <div className="form-group">
                    <span style={{ width: "100%", textAlign: "initial" }}>
                      Select Type
                    </span>

                    <select
                      class="form-select form-select-lg mb-3"
                      aria-label=".form-select-lg example"
                      id="transport_type_id"
                      name="transport_type_id"
                      onChange={this.handleTransferType}
                    >
                      {/* <option value="0">All</option> */}
                      <option value="1">Airport Transfers </option>
                      <option value="2">Intercity Transfers</option>
                      {/* <option value="4">Intercity Transfers Round Trip</option> */}
                      <option value="3">Ferry Transfers</option>
                    </select>
                  </div>
                </div>
              </div>

              {this.state.showPickupType && (
                <div className="col-lg-2 col-md-2 col-xs-12 col-sm-12Allhover">
                  <div className="bor">
                    <div className="">
                      <span style={{ width: "100%", textAlign: "initial" }}>
                        Pickup Type
                      </span>

                      <select
                        class="form-select form-select-lg mb-3"
                        aria-label=".form-select-lg example"
                        id="transport_type_id"
                        name="transport_type_id"
                        onChange={this.handlePickupType}
                      >
                        <option value="1">From Airport</option>
                        <option value="2">To Airport</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {this.state.showFromAirPort && (
                <>
                  <div
                    className="col-lg-3 col-md-3 col-xs-12 col-sm-12 Allhover"
                    onClick={this.showZonelistdivAirportTransfer}
                  >
                    <div className="bor">
                      <div className="">
                        <span style={{ width: "100%", textAlign: "initial" }}>
                          From Air Port
                        </span>
                        <p className="defaultZone">
                          {this.state.defaultZonevalAirportTransfer.length >= 60
                            ? `${this.state.defaultZonevalAirportTransfer.substring(
                                0,
                                60
                              )}...`
                            : this.state.defaultZonevalAirportTransfer}
                        </p>
                        {this.state.showResultsAirportTransfer ? (
                          <div
                            className="autocomplete-wrapper"
                            style={{ top: 148, marginLeft: "33%" }}
                            onClick={this.handleBodyClick}
                          >
                            <Autosuggest
                              suggestions={suggestionsAirportTransfer}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequestedAirportTransfer
                              }
                              onSuggestionSelected={
                                this.onSuggestionSelectedAirportTransfer
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequestedAirportTransfer
                              }
                              getSuggestionValue={
                                this.getSuggestionValueAirportTransfer
                              }
                              renderSuggestionsContainer={
                                this.renderSuggestionsContainerAirportTransfer
                              }
                              renderSuggestion={
                                this.renderSuggestionAirportTransfer
                              }
                              inputProps={inputPropsAirportTransfer}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-xs-12 col-sm-12 Allhover"
                    onClick={this.showZonelistdivAirportToCityTransfer}
                  >
                    <div className="bor">
                      <div className="">
                        <span style={{ width: "100%", textAlign: "initial" }}>
                          {" "}
                          City
                        </span>
                        <p className="defaultZone">
                          {this.state.defaultZonevalAirportToCityTransfer
                            .length >= 60
                            ? `${this.state.defaultZonevalAirportToCityTransfer.substring(
                                0,
                                60
                              )}...`
                            : this.state.defaultZonevalAirportToCityTransfer}
                        </p>
                        {this.state.showResultsAirportToCityTransfer ? (
                          <div
                            className="autocomplete-wrapper"
                            style={{ top: 148, marginLeft: "58%" }}
                            onClick={this.handleBodyClick}
                          >
                            <Autosuggest
                              suggestions={suggestionsAirportToCityTransfer}
                              onSuggestionsFetchRequested={
                                this
                                  .onSuggestionsFetchRequestedAirportToCityTransfer
                              }
                              onSuggestionSelected={
                                this.onSuggestionSelectedAirportToCityTransfer
                              }
                              onSuggestionsClearRequested={
                                this
                                  .onSuggestionsClearRequestedAirportToCityTransfer
                              }
                              getSuggestionValue={
                                this.getSuggestionValueAirportToCityTransfer
                              }
                              renderSuggestionsContainer={
                                this
                                  .renderSuggestionsContainerAirportToCityTransfer
                              }
                              renderSuggestion={
                                this.renderSuggestionAirportToCityTransfer
                              }
                              inputProps={inputPropsAirportToCityTransfer}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {this.state.showToAirPort && (
                <>
                  <div
                    className="col-lg-3 col-md-3 col-xs-12 col-sm-12 Allhover"
                    onClick={this.showZonelistdivCityTransfer}
                  >
                    <div className="bor">
                      <div className="">
                        <span style={{ width: "100%", textAlign: "initial" }}>
                          {" "}
                          City
                        </span>
                        <p className="defaultZone">
                          {this.state.defaultZonevalCityTransfer.length >= 60
                            ? `${this.state.defaultZonevalCityTransfer.substring(
                                0,
                                60
                              )}...`
                            : this.state.defaultZonevalCityTransfer}
                        </p>
                        {this.state.showResultsCityTransfer ? (
                          <div
                            className="autocomplete-wrapper"
                            style={{ top: 148, marginLeft: "33%" }}
                            onClick={this.handleBodyClick}
                          >
                            <Autosuggest
                              suggestions={suggestionsCityTransfer}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequestedCityTransfer
                              }
                              onSuggestionSelected={
                                this.onSuggestionSelectedCityTransfer
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequestedCityTransfer
                              }
                              getSuggestionValue={
                                this.getSuggestionValueCityTransfer
                              }
                              renderSuggestionsContainer={
                                this.renderSuggestionsContainerCityTransfer
                              }
                              renderSuggestion={
                                this.renderSuggestionCityTransfer
                              }
                              inputProps={inputPropsCityTransfer}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-xs-12 col-sm-12 Allhover"
                    onClick={this.showZonelistdivToAirportTransfer}
                  >
                    <div className="bor">
                      <div className="">
                        <span style={{ width: "100%", textAlign: "initial" }}>
                          AirPort
                        </span>
                        <p className="defaultZone">
                          {this.state.defaultZonevalToAirportTransfer.length >=
                          60
                            ? `${this.state.defaultZonevalToAirportTransfer.substring(
                                0,
                                60
                              )}...`
                            : this.state.defaultZonevalToAirportTransfer}
                        </p>
                        {this.state.showResultsToAirportTransfer ? (
                          <div
                            className="autocomplete-wrapper"
                            style={{ top: 148, marginLeft: "58%" }}
                            onClick={this.handleBodyClick}
                          >
                            <Autosuggest
                              suggestions={suggestionsToAirportTransfer}
                              onSuggestionsFetchRequested={
                                this
                                  .onSuggestionsFetchRequestedToAirportTransfer
                              }
                              onSuggestionSelected={
                                this.onSuggestionSelectedToAirportTransfer
                              }
                              onSuggestionsClearRequested={
                                this
                                  .onSuggestionsClearRequestedToAirportTransfer
                              }
                              getSuggestionValue={
                                this.getSuggestionValueToAirportTransfer
                              }
                              renderSuggestionsContainer={
                                this.renderSuggestionsContainerToAirportTransfer
                              }
                              renderSuggestion={
                                this.renderSuggestionToAirportTransfer
                              }
                              inputProps={inputPropsToAirportTransfer}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {this.state.showFerryType && (
                <>
                  <div
                    className="col-lg-4 col-md-4 col-xs-12 col-sm-12 Allhover"
                    onClick={this.showZonelistdivFerryTransfer}
                  >
                    <div className="bor">
                      <div className="">
                        <span style={{ width: "100%", textAlign: "initial" }}>
                          From Port
                        </span>
                        <p className="defaultZone">
                          {this.state.defaultZonevalFerryTransfer.length >= 60
                            ? `${this.state.defaultZonevalFerryTransfer.substring(
                                0,
                                60
                              )}...`
                            : this.state.defaultZonevalFerryTransfer}
                        </p>
                        {this.state.showResultsFerryTransfer ? (
                          <div
                            className="autocomplete-wrapper"
                            style={{ top: 148, marginLeft: "17%" }}
                            onClick={this.handleBodyClick}
                          >
                            <Autosuggest
                              suggestions={suggestionsFerryTransfer}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequestedFerryTransfer
                              }
                              onSuggestionSelected={
                                this.onSuggestionSelectedFerryTransfer
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequestedFerryTransfer
                              }
                              getSuggestionValue={
                                this.getSuggestionValueFerryTransfer
                              }
                              renderSuggestionsContainer={
                                this.renderSuggestionsContainerFerryTransfer
                              }
                              renderSuggestion={
                                this.renderSuggestionFerryTransfer
                              }
                              inputProps={inputPropsFerryTransfer}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-xs-12 col-sm-12 Allhover"
                    onClick={this.showZonelistdivFerryToTransfer}
                  >
                    <div className="bor">
                      <div className="">
                        <span style={{ width: "100%", textAlign: "initial" }}>
                          To Port
                        </span>
                        <p className="defaultZone">
                          {this.state.defaultZonevalFerryToTransfer.length >= 60
                            ? `${this.state.defaultZonevalFerryToTransfer.substring(
                                0,
                                60
                              )}...`
                            : this.state.defaultZonevalFerryToTransfer}
                        </p>
                        {this.state.showResultsFerryToTransfer ? (
                          <div
                            className="autocomplete-wrapper"
                            style={{ top: 148, marginLeft: "50%" }}
                            onClick={this.handleBodyClick}
                          >
                            <Autosuggest
                              suggestions={suggestionsFerryToTransfer}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequestedFerryToTransfer
                              }
                              onSuggestionSelected={
                                this.onSuggestionSelectedFerryToTransfer
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequestedFerryToTransfer
                              }
                              getSuggestionValue={
                                this.getSuggestionValueFerryToTransfer
                              }
                              renderSuggestionsContainer={
                                this.renderSuggestionsContainerFerryToTransfer
                              }
                              renderSuggestion={
                                this.renderSuggestionFerryToTransfer
                              }
                              inputProps={inputPropsFerryToTransfer}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {this.state.ShowTransportType && (
                <>
                  <div
                    className="col-lg-4 col-md-4 col-xs-12 col-sm-12 Allhover"
                    onClick={this.showZonelistdivSourceTransfer}
                  >
                    <div className="bor">
                      <div className="">
                        <span style={{ width: "100%", textAlign: "initial" }}>
                          Source Location
                        </span>
                        <p className="defaultZone">
                          {this.state.defaultZonevalSourceTransfer.length >= 60
                            ? `${this.state.defaultZonevalSourceTransfer.substring(
                                0,
                                60
                              )}...`
                            : this.state.defaultZonevalSourceTransfer}
                        </p>
                        {this.state.showResultsSourceTransfer ? (
                          <div
                            className="autocomplete-wrapper"
                            style={{ top: 148, marginLeft: "17%" }}
                            onClick={this.handleBodyClick}
                          >
                            <Autosuggest
                              suggestions={suggestionsSourceTransfer}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequestedSourceTransfer
                              }
                              onSuggestionSelected={
                                this.onSuggestionSelectedSourceTransfer
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequestedSourceTransfer
                              }
                              getSuggestionValue={
                                this.getSuggestionValueSourceTransfer
                              }
                              renderSuggestionsContainer={
                                this.renderSuggestionsContainerSourceTransfer
                              }
                              renderSuggestion={
                                this.renderSuggestionSourceTransfer
                              }
                              inputProps={inputPropsSourceTransfer}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-xs-12 col-sm-12 Allhover"
                    onClick={this.showZonelistdivDestinationTransfer}
                  >
                    <div className="bor">
                      <div className="">
                        <span style={{ width: "100%", textAlign: "initial" }}>
                          Destination Location
                        </span>
                        <p className="defaultZone">
                          {this.state.defaultZonevalDestinationTransfer
                            .length >= 60
                            ? `${this.state.defaultZonevalDestinationTransfer.substring(
                                0,
                                60
                              )}...`
                            : this.state.defaultZonevalDestinationTransfer}
                        </p>
                        {this.state.showResultsDestinationTransfer ? (
                          <div
                            className="autocomplete-wrapper"
                            style={{ top: 148, marginLeft: "50%" }}
                            onClick={this.handleBodyClick}
                          >
                            <Autosuggest
                              suggestions={suggestionsDestinationTransfer}
                              onSuggestionsFetchRequested={
                                this
                                  .onSuggestionsFetchRequestedDestinationTransfer
                              }
                              onSuggestionSelected={
                                this.onSuggestionSelectedDestinationTransfer
                              }
                              onSuggestionsClearRequested={
                                this
                                  .onSuggestionsClearRequestedDestinationTransfer
                              }
                              getSuggestionValue={
                                this.getSuggestionValueDestinationTransfer
                              }
                              renderSuggestionsContainer={
                                this
                                  .renderSuggestionsContainerDestinationTransfer
                              }
                              renderSuggestion={
                                this.renderSuggestionDestinationTransfer
                              }
                              inputProps={inputPropsDestinationTransfer}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div
                className="col-lg-2 col-md-2 col-xs-12 col-sm-12 Allhover"
                onClick={this.checkinandcheckoutTransfer}
              >
                <div className="bor">
                  <span style={{ width: "100%", textAlign: "initial" }}>
                    Add when you want to go
                  </span>
                  <p className="defaultZone">{this.state.selectTransferDay}</p>
                  <div onClick={this.handleBodyClick}>
                    {this.state.checkinShowTransfer && (
                      <DayPicker
                        className="activityDate newTransferDay"
                        onDayClick={this.handleTransferDayClick}
                        selectedDays={this.state.selectTransferDayNew}
                        minDate={new Date()}
                        disabledDays={{ before: new Date() }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sercheng">
            <a
              href={
                "/transferlisting?defaultZonevalSourceTransferVal=" +
                encodeURIComponent(this.state.defaultZonevalSourceTransfer) +
                "&defaultZonevalDestinationTransferVal=" +
                encodeURIComponent(
                  this.state.defaultZonevalDestinationTransfer
                ) +
                "&defaultZonevalAirportTransferVal=" +
                encodeURIComponent(this.state.defaultZonevalAirportTransfer) +
                "&defaultZonevalAirportToCityTransferVal=" +
                encodeURIComponent(
                  this.state.defaultZonevalAirportToCityTransfer
                ) +
                "&defaultZonevalToAirportTransferVal=" +
                encodeURIComponent(this.state.defaultZonevalToAirportTransfer) +
                "&defaultZonevalFerryTransferVal=" +
                encodeURIComponent(this.state.defaultZonevalFerryTransfer) +
                "&defaultZonevalFerryToTransferVal=" +
                encodeURIComponent(this.state.defaultZonevalFerryToTransfer) +
                "&defaultZonevalCityTransferVal=" +
                encodeURIComponent(this.state.defaultZonevalCityTransfer) +
                "&transfertype=" +
                encodeURIComponent(this.state.selectTransferType) +
                "&pickuptype=" +
                encodeURIComponent(this.state.selectPickupType) +
                "&dateSelected=" +
                encodeURIComponent(this.state.selectTransferDayNew)
              }
              className="btn_searchs"
            >
              SEARCH
            </a>
          </div>
        </BannerTab>

        <BannerTab isSelected={this.props.isSelected === "Liveaboard"}>
          <div className="serch-top-bar">
            <div className="row">
              <div className="col-md-12">
                <h6 className="justify-content-center">
                  Book any kind of liveaboard
                </h6>
              </div>

              <div
                className="col-lg-6 col-md-6 col-xs-12 col-sm-12 Allhover"
                onClick={this.showZonelistdivLiveAboard}
              >
                <div className="bor">
                  <span style={{ width: "100%", textAlign: "initial" }}>
                    Location or liveaboard name
                  </span>
                  <p className="defaultZone">
                    {this.state.defaultZonevalLiveAboard.length >= 60
                      ? `${this.state.defaultZonevalLiveAboard.substring(
                          0,
                          60
                        )}...`
                      : this.state.defaultZonevalLiveAboard}
                  </p>
                  {this.state.showResultsLiveAboard ? (
                    <div
                      className="autocomplete-wrapper"
                      style={{ top: 148 }}
                      onClick={this.handleBodyClick}
                    >
                      <Autosuggest
                        suggestions={suggestionsLiveAboard}
                        onSuggestionsFetchRequested={
                          this.onSuggestionsFetchRequestedLiveAboard
                        }
                        onSuggestionSelected={
                          this.onSuggestionSelectedLiveAboard
                        }
                        onSuggestionsClearRequested={
                          this.onSuggestionsClearRequestedLiveAboard
                        }
                        getSuggestionValue={this.getSuggestionValueLiveAboard}
                        renderSuggestionsContainer={
                          this.renderSuggestionsContainerLiveAboard
                        }
                        renderSuggestion={this.renderSuggestionLiveAboard}
                        inputProps={inputPropsLiveAboard}
                      />
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                className="col-lg-6 col-md-6 col-xs-12 col-sm-12 Allhover"
                onClick={this.checkinandcheckoutLiveAboard}
              >
                <div className="bor">
                  <span style={{ width: "100%", textAlign: "initial" }}>
                    Add when you want to go
                  </span>
                  <p className="defaultZone">
                    {this.state.selectLiveAboardDay}
                  </p>
                  <div onClick={this.handleBodyClick}>
                    {this.state.checkinShowLiveAboard && (
                      <DayPicker
                        className="activityDate"
                        onDayClick={this.handleLiveAboardDayClick}
                        selectedDays={this.state.selectLiveAboardDayNew}
                        minDate={new Date()}
                        disabledDays={{ before: new Date() }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sercheng">
            <a
              href={
                "/liveaboardlisting?defaultZonevalLiveAboardVal=" +
                encodeURIComponent(this.state.defaultZonevalLiveAboard) +
                "&dateSelected=" +
                encodeURIComponent(this.state.selectLiveAboardDayNew)
              }
              className="btn_searchs"
            >
              SEARCH
            </a>
          </div>
        </BannerTab>
      </>
    );
  }
}
export default Banner;
