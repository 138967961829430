/* eslint-disable */
import React, { Component } from "react";
import InputRange from "react-input-range";
import {Helmet} from "react-helmet";
import "react-input-range/lib/css/index.css";
import "./HotelListing.css";
import {
  zonelistAccoData,
  HotelListData,
  getHotellistFilters,
  SupplierUrl
} from "../Component/axios";
import Autosuggest from "react-autosuggest";
import Modal from "react-modal";
import HotelList from "../Component/HostelList/HotelList";
import HotelListSkeleton from "../Component/HostelList/HotelListSkeleton";
import NewHeader from "../Component/NewHeader/NewHeader";
import GoogleMap from "../Component/GoogleMap";
import { List } from "react-virtualized";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuroSign,
  faHotel
} from "@fortawesome/free-solid-svg-icons";

import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import  AccommodationSoldout  from "./SoldOut/AccommodationSoldout";
import TawkToWidget from "../Component/TawkToWidget";
const ListNumber = [
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" },
  { id: "5" },
  { id: "6" },
  { id: "7" },
  { id: "8" },
  { id: "9" },
  { id: "10" },
  { id: "11" },
  { id: "12" },
  { id: "13" },
];

const SortArr = [
  { id: "4", name: "Recommended" },
  { id: "1", name: "Price - Low to High" },
  { id: "2", name: "Price - High to Low" },
  { id: "3", name: "User Rating - High to Low" },
];

export default class HotelListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      isOpen: false,
      dropdownVisible: false,
      selected: "Accommodations",
      searchInput: "",
      itemsZone: [],
      error: null,
      val: "",
      value: "",
      suggestions: [],
      showResults: false,
      defaultZoneval: "Seychelles",
      modalIsOpen: false,
      SelectedPlace: null,
      nav: false,
      Zoneval: "",
      hotelList: null,
      hotelListFilter: null,
      guestCombination: "",
      cats: [
        { adultCounter: 1, childCounter: 0, roomCounter: 1, isExpanded: true },
      ],
      searchData: "",
      sorting: false,
      sortingSelect: "Recommended",
      sortingId: null,
      FiltersData: null,
      valuePrice: { min: 0, max: 10 },
      appliedFilterVal: [],
      checked: false,
      checkedItems: new Map(),
      accopage: 1,
      accosize: 20,
      loading: false,
      prevY: 0
      
    };
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  afterOpenModal = () => {
    subtitle.style.color = "#f00";
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  setSelectedTabOptions = (tab) => {
    this.setState({ selected: tab });
  };

  setDatefunction(startDate, endDate) {
  
    this.setState({ startDate, endDate });
  }
  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  handleEvent = (id) => {
    this.setState({ SelectedPlace: id });
  };

  increment = () => {
    if (this.state.adultCounter < 15) {
      this.setState({ adultCounter: this.state.adultCounter + 1 });
    }
  };

  decrement = () => {
    if (this.state.adultCounter > 0) {
      this.setState({ adultCounter: this.state.adultCounter - 1 });
    }
  };

  incrementChild = () => {
    if (this.state.adultCounter == 0) {
      this.setState({ adultCounter: this.state.adultCounter + 1 });
    }
    if (this.state.childCounter < 6) {
      this.setState({ childCounter: this.state.childCounter + 1 });
    }
  };

  decrementChild = () => {
    if (this.state.childCounter > 0) {
      this.setState({ childCounter: this.state.childCounter - 1 });
    }
  };

  incrementRoom = () => {
    if (this.state.roomCounter < 10) {
      this.setState({ roomCounter: this.state.roomCounter + 1 });
    }
  };

  decrementRoom = () => {
    if (this.state.roomCounter > 0) {
      this.setState({ roomCounter: this.state.roomCounter - 1 });
    }
  };
  handleScroll = () => {
    if (window.pageYOffset > 10) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };
  showSorting = (syntheticEvent) => {
    
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ sorting: !prevState.sorting }),
      () => {
        if (this.state.sorting) {
       
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  componentDidMount() {
   
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const defaultZoneval = urlParams.get("defaultZoneval");
    const checkin = urlParams.get("checkin");
    const checkout = urlParams.get("checkout");
    const guestCombination = urlParams.get("guestCombination");

    let querySearch = JSON.parse(guestCombination);

    this.setState({ defaultZoneval: defaultZoneval });
    this.setState({ startDate: checkin });
    this.setState({ endDate: checkout });
    this.setState({ cats: querySearch });

    let JsonState = {
      defaultZoneval: defaultZoneval,
      startDate: moment(checkin).format("Y-MM-DD"),
      endDate: moment(checkout).format("Y-MM-DD"),
      guestCombination: querySearch,
      accopage: this.state.accopage,
      accosize: this.state.accosize
    };
    const hotelArr = JSON.stringify(JsonState);
 
    this.setState({ searchData: hotelArr });
    zonelistAccoData().then(
      (data) => {
        this.setState({
          itemsZone: data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  let dateString = new Date(); // date string
  let actualDate = new Date(dateString); // convert to actual date
  let CheckNewData = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate() + 3);
  let DataCreate = moment(CheckNewData).format("Y-MM-DD");
  let startdate =  moment(checkin).format("Y-MM-DD");
 
    HotelListData(hotelArr).then(
      (data) => {
    if (data == undefined) {
      this.setState({
        hotelList: null,
      });
    } else {
      if (data == "nohotel") {
        this.setState({
          hotelList: data,
        });
      } else {
      this.setState({ hotelList: data });
      this.setState({ hotelListFilter: data });
      }
    }
      },
      (error) => {
        this.setState({
          error,
        });
      }
      );
 
    getHotellistFilters(hotelArr).then(
      (data) => {
      
        if (data != "no-Filter") {
          this.setState({ valuePrice: { min: 0, max: data.budget.maxPrice } });
        }
        this.setState({ FiltersData: data });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
 
    window.addEventListener("scroll", this.handleScroll);

  }

  SortingFunction = (data) => {

      if (data != "nohotel") {
      const sorArr = data;
      const zeroElement = sorArr[0];

      setTimeout(() => {
        if (sorArr[0].matched) {
          const sliceArr = sorArr.shift();
          const getValue = (price) => +price;
          // sorArr.sort(
          //   (a, b) =>
          //     getValue(
          //       a.details.property_source == 1
          //         ? a.ejuniper.NewAmount == "0.00"
          //           ? a.ejuniper.HotelOfferAmount
          //           : a.ejuniper.NewAmount
          //         : a.details.property_source == 2
          //         ? a.hotellink.amount
          //         : a.hs_portal.amount
          //     ) -
          //     getValue(
          //       b.details.property_source == 1
          //         ? b.ejuniper.NewAmount == "0.00"
          //           ? b.ejuniper.HotelOfferAmount
          //           : b.ejuniper.NewAmount
          //         : b.details.property_source == 2
          //         ? b.hotellink.amount
          //         : b.hs_portal.amount
          //     )
          // );

       
          const newArr = sorArr.splice(0, 0, sliceArr);

          // this.setState({
          //   hotelList: sorArr,
          // });
          // this.setState({ hotelListFilter: sorArr });
          if (this.state.defaultZoneval.toLowerCase().trim() === 'seychelles') {
            this.setState({ hotelList: [...this.state.hotelList, ...sorArr] });
            this.setState({ hotelListFilter: [...this.state.hotelListFilter, ...sorArr] });
          } else {
            // this.setState({
            //   hotelList: sorArr
            // });
            // this.setState({ hotelListFilter: sorArr });
            this.setState({ hotelList: [...this.state.hotelList, ...sorArr] });
            this.setState({ hotelListFilter: [...this.state.hotelListFilter, ...sorArr] });
          }
        } else {

          // const getValue = (price) => +price;
          // sorArr.sort(
          //   (a, b) =>
          //     getValue(
          //       a.details.property_source == 1
          //         ? a.ejuniper.NewAmount == "0.00"
          //           ? a.ejuniper.HotelOfferAmount
          //           : a.ejuniper.NewAmount
          //         : a.details.property_source == 2
          //         ? a.hotellink.amount
          //         : a.hs_portal.amount
          //     ) -
          //     getValue(
          //       b.details.property_source == 1
          //         ? b.ejuniper.NewAmount == "0.00"
          //           ? b.ejuniper.HotelOfferAmount
          //           : b.ejuniper.NewAmount
          //         : b.details.property_source == 2
          //         ? b.hotellink.amount
          //         : b.hs_portal.amount
          //     )
          // );

        

          this.setState({
            hotelList: sorArr,
          });
          this.setState({ hotelListFilter: sorArr });
          // this.setState({ hotelList: [...this.state.hotelList, ...sorArr] });
          // this.setState({ hotelListFilter: [...this.state.hotelListFilter, ...sorArr] });
          // if (this.state.defaultZoneval.toLowerCase().trim() === 'seychelles') {
          //   this.setState({ hotelList: [...this.state.hotelList, ...sorArr] });
          //   this.setState({ hotelListFilter: [...this.state.hotelListFilter, ...sorArr] });
          // } else {
          //   this.setState({
          //     hotelList: sorArr
          //   });
          //   this.setState({ hotelListFilter: sorArr });
          // }
        }
      }, 1000);
    }
  // })
   
  };

  SearchListing = () => {

  
    let JsonState = {
      defaultZoneval: this.state.defaultZoneval,
      startDate: moment(this.state.startDate).format("Y-MM-DD"),
      endDate: moment(this.state.endDate).format("Y-MM-DD"),
      guestCombination: this.state.cats,
      accopage: 1,
      accosize: this.state.accosize
    };
    const hotelArr = JSON.stringify(JsonState);

    this.setState({
      hotelList: null,
    });
    this.setState({
      accopage: 1,
    });
    this.setState({ searchData: hotelArr });
  
    let dateString = new Date(); // date string
    let actualDate = new Date(dateString); // convert to actual date
    let CheckNewData = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate() + 3);
    let DataCreate = moment(CheckNewData).format("Y-MM-DD");
   let checkin = moment(this.state.startDate).format("Y-MM-DD")
  
 

    HotelListData(hotelArr).then(
      (data) => {
    if (data == undefined) {
      this.setState({
        hotelList: null,
      });
    } else {
      if (data == "nohotel") {
        this.setState({
          hotelList: data,
        });
      } else {
       this.setState({ hotelList: data });
       this.setState({ hotelListFilter: data });
      }
    }
      },
      (error) => {
        this.setState({
          error,
        });
      }
      );
 
    getHotellistFilters(hotelArr).then(
      (data) => {

        if (data != "no-Filter") {
          this.setState({ valuePrice: { min: 0, max: data.budget.maxPrice } });
        }
        this.setState({ FiltersData: data });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    
  };

  sortingSearch = (data) => {
 
    const sorArr = data;
    if (this.state.sortingSelect == "Price - Low to High") {

      setTimeout(() => {
        if (sorArr[0].matched) {
          const sliceArr = sorArr.shift();
          const getValue = (price) => +price;
          sorArr.sort(
            (a, b) =>
              getValue(
                a.details.property_source == 1
                  ? a.ejuniper.NewAmount == "0.00"
                    ? a.ejuniper.HotelOfferAmount
                    : a.ejuniper.NewAmount
                  : a.details.property_source == 2
                  ? a.hotellink.amount
                  : a.hs_portal.amount
              ) -
              getValue(
                b.details.property_source == 1
                  ? b.ejuniper.NewAmount == "0.00"
                    ? b.ejuniper.HotelOfferAmount
                    : b.ejuniper.NewAmount
                  : b.details.property_source == 2
                  ? b.hotellink.amount
                  : b.hs_portal.amount
              )
          );

        

      

          const newArr = sorArr.splice(0, 0, sliceArr);

    

          this.setState({
            hotelList: sorArr,
          });
          this.setState({ hotelListFilter: sorArr });
        } else {
          const getValue = (price) => +price;
          sorArr.sort(
            (a, b) =>
              getValue(
                a.details.property_source == 1
                  ? a.ejuniper.NewAmount == "0.00"
                    ? a.ejuniper.HotelOfferAmount
                    : a.ejuniper.NewAmount
                  : a.details.property_source == 2
                  ? a.hotellink.amount
                  : a.hs_portal.amount
              ) -
              getValue(
                b.details.property_source == 1
                  ? b.ejuniper.NewAmount == "0.00"
                    ? b.ejuniper.HotelOfferAmount
                    : b.ejuniper.NewAmount
                  : b.details.property_source == 2
                  ? b.hotellink.amount
                  : b.hs_portal.amount
              )
          );

       

          this.setState({
            hotelList: sorArr,
          });
          this.setState({ hotelListFilter: sorArr });
        }
      }, 2000);
    } else if (this.state.sortingSelect == "Price - High to Low") {
      setTimeout(() => {
        const getValue = (price) => -price;
        sorArr.sort(
          (a, b) =>
            getValue(
              a.details.property_source == 1
                ? a.ejuniper.NewAmount == "0.00"
                  ? a.ejuniper.HotelOfferAmount
                  : a.ejuniper.NewAmount
                : a.details.property_source == 2
                ? a.hotellink.amount
                : a.hs_portal.amount
            ) -
            getValue(
              b.details.property_source == 1
                ? b.ejuniper.NewAmount == "0.00"
                  ? b.ejuniper.HotelOfferAmount
                  : b.ejuniper.NewAmount
                : b.details.property_source == 2
                ? b.hotellink.amount
                : b.hs_portal.amount
            )
        );

    

        this.setState({
          hotelList: sorArr,
        });
        this.setState({ hotelListFilter: sorArr });
      }, 2000);
    }
  };

  componentWillUnmount() {
    // document.removeEventListener('click', this.globalClickListener)
    window.removeEventListener("click", this.globalClickListener);
    window.removeEventListener("scroll", this.handleScroll);
  }
  globalClickListener = (nativeEvent) => {
    this.setState(
      { dropdownVisible: false, showResults: false, sorting: false },
      () => {
     
        window.removeEventListener("click", this.globalClickListener);
      }
    );
  };
  toggleDropdown = (syntheticEvent) => {
    this.setState({ checkinShow: false, showResults: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ dropdownVisible: !prevState.dropdownVisible }),
      () => {
        if (this.state.dropdownVisible) {
      
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  
  };
  handleBodyClick = (event) => {
 
    event.stopPropagation();
  };

  renderDropdownMenu() {
   
    return (
      <div className="roomsGuestsList" onClick={this.handleBodyClick}>
          <div className="roomsGuestsTop">
        {this.state.cats.map((val, idx) => {
          if (idx <= 2) {
            return (
              <div className="rooms" key={idx}>
                <div className="addRoomRowhs">
                  <div className="addRoomLeft">
                    <p className="darkText font16 latoBlack capText appendBottom10">
                      Room {idx + 1}
                    </p>
                  </div>
                  <div className="addRoomRight">
                    {val.isExpanded == true ? (
                      <div className="addRooomDetails">
                        <p className="spaceBetween appendBottom10">
                          <span className="latoBold font12 grayText">
                            Adults (12y +){" "}
                          </span>
                        </p>
                        <ul className="guestCounter font12 darkText">
                          {ListNumber.map((item, index) => (
                            <li
                              className={
                                this.state.cats[idx].adultCounter == item.id
                                  ? "selected"
                                  : ""
                              }
                              key={index}
                              onClick={() =>
                                this.handleInputChange(item.id, idx)
                              }
                            >
                              {item.id}
                            </li>
                          ))}
                        </ul>
                        <div>
                          <p className="latoBold font12 grayText appendBottom10">
                            Children (1y - 12y )
                          </p>
                          <ul className="guestCounter font12 darkText">
                            {ListNumber.slice(0, 6).map((item, index) => (
                              <li
                                className={
                                  this.state.cats[idx].childCounter == index
                                    ? "selected"
                                    : ""
                                }
                                key={index}
                                onClick={() =>
                                  this.handleInputChangeChild(index, idx)
                                }
                              >
                                {index}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div className="editRoom">
                        <p className="hdmakeFlex end">
                          <span className="font16 blackText">
                            <span className="latoBlack">
                              {this.state.cats[idx].adultCounter}
                            </span>{" "}
                            Adults,
                            <span className="latoBlack">
                              {" "}
                              {this.state.cats[idx].childCounter}
                            </span>{" "}
                            Children
                          </span>
                          <span
                            className="font12 appendLeft10 editRoomClass"
                            onClick={() => this.handleEditRoomClick(idx)}
                          >
                            Edit{" "}
                          </span>
                        </p>
                        <span
                          className="font12 editRoomClass"
                          key={idx}
                          onClick={() => this.handleRemoveClick(idx)}
                        >
                          Remove
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
        </div>
        <div className="roomsGuestsBottom">
        {this.state.cats.length <=2 &&(<span className="btnAddRoom" onClick={this.addRoom}>+ Add Another Room{" "}</span>)}
          <span className="submit-button" onClick={this.applyRoom}>
            Apply
          </span>
        </div>
      </div>
    );
  }

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : this.state.itemsZone.filter(
          (lang) => lang.Name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) => {
   
    return (
      <div
        key={key}
        className="hotelListing"
        style={style}
        onMouseOver={(e) =>
          this.handleEvent(this.state.hotelList[index].details.id)
        }
        onMouseOut={(e) =>
          this.handleEvent(this.state.hotelList[index].details.id)
        }
      >
        
        <div className="hotelListingItem ">
          {" "}
          <span className="listingImg">
            <img
              src={this.state.hotelList[index].details.property_source == 1 ?
                this.state.hotelList[index].property_gallery == null
                  ? "images/placeholder.png"
                  : this.state.hotelList[index].property_gallery[0].image:
                  this.state.hotelList[index].property_gallery == null
                  ? "images/placeholder.png"
                  :SupplierUrl+"/uploads/property_gallery_main/" + this.state.hotelList[index].property_gallery[0].image
              }
              alt=""
            />
          </span>
          <div className="listingDetails">
            <p className="makeFlex1 hrtlCenter appendBottom10">
              <span className="hotelRatingTa greenBgTA whiteText font9">
                <span className="sprite icTaRating appendRight3"></span>
                <span className="latoBold">4.0</span>/5
              </span>
            </p>
            <p className="latoBold font14 blackText appendBottom3">
              {this.state.hotelList[index].details.property_name}
            </p>
            <div
              className="makeFlex1 spaceBetween"
              style={{ marginTop: "-10px" }}
            >
              <div className="flexOne">
                <p
                  className="latoBold font12 grayText appendBottom3"
                  style={{ textAlign: "left", marginTop: 15 }}
                  alt="Massy"
                >
                  {this.state.hotelList[index].details.address_line_1}
                </p>
              </div>
              <div className="noShring textRight" style={{ marginTop: 15 }}>
                {this.state.hotelList[index].details.property_source == 1 ? (
                  <>
                    {this.state.hotelList[index].ejuniper.HotelOfferAmount ==
                    "" ? (
                      <p
                        className="latoBlack font12 blackText appendBottom5"
                        id="hlistpg_hotel_shown_price"
                      >
                        €
                        {(
                          Math.round(
                            this.state.hotelList[index].ejuniper.NewAmount * 100
                          ) / 100
                        ).toFixed(2)}
                      </p>
                    ) : (
                      <>
                        {" "}
                        {parseInt(
                          this.state.hotelList[index].ejuniper.PercentageAm
                        ) == 0 ? (
                          <p
                            className="latoBlack font12 blackText appendBottom5"
                            id="hlistpg_hotel_shown_price"
                          >
                            €
                            {(
                              Math.round(
                                this.state.hotelList[index].ejuniper.Amount *
                                  100
                              ) / 100
                            ).toFixed(2)}
                          </p>
                        ) : (
                          <>
                            <p
                              className="latoBlack font12 blackText appendBottom5"
                              id="hlistpg_hotel_shown_price"
                            >
                              €
                              {(
                                Math.round(
                                  this.state.hotelList[index].ejuniper
                                    .HotelOfferAmount * 100
                                ) / 100
                              ).toFixed(2)}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {this.state.hotelList[index].details.property_source ==
                    2 ? (
                      <>
                        {this.state.hotelList[index].hotellink == "" ? (
                          <></>
                        ) : (
                          <>
                            <p
                              className="latoBlack font12 blackText appendBottom5"
                              id="hlistpg_hotel_shown_price"
                            >
                              €{this.state.hotelList[index].hotellink.amount}
                            </p>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.hotelList[index].hs_portal == "" ? (
                          <></>
                        ) : (
                          <>
                            <p
                              className="latoBlack font12 blackText appendBottom5"
                              id="hlistpg_hotel_shown_price"
                            >
                              {this.state.hotelList[index].hs_portal.amount &&(<>€{this.state.hotelList[index].hs_portal.amount}</>)}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                {/* <p className="font12 darkGreyText">+₹ 3,851 taxes &amp; fees</p> */}
                {this.state.cats.reduce((a, v) => a + v.roomCounter, 0) > 1 ? (
                  <p className="font12 grayText appendBottom5">
                    Per Night for{" "}
                    {this.state.cats.reduce((a, v) => a + v.roomCounter, 0)}{" "}
                    Rooms{" "}
                  </p>
                ) : (
                  <p className="font12 grayText appendBottom5">Per Night </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = (suggestion) => suggestion.Name;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion, { query, isHighlighted }) => (
    // <div className="font14 darkText  noChild  clickable">
    //   <div className="spaceBetween makeFlex hrtlCenter ">
    //     <div className="flexOne">
    //       <p className="locusLabel appendBottom5">{suggestion.Name}</p>
    //     </div>
    //     <span
    //       className="width60 font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight"
    //       style={{ width: "100px", padding: "0px" }}
    //     >
    //       {suggestion.AreaType}
    //     </span>
    //   </div>
    // </div>
    <div className="font14 darkText  noChild  clickable">
      <div className="spaceBetween makeFlex hrtlCenter ">
      {suggestion.AreaType == 'City' || suggestion.AreaType == 'Country'  ?(<div className="locationImageLocation">
          <img
          src="images/locationacco.png"
          className="locationImageContent"
          />
      </div>):(<FontAwesomeIcon icon={faHotel} className="appendLeft5 appendRight12 textLeft"
          style={{ fontSize: "16px", marginRight:10, color: "#ffaa52" }}/>)}

        <div className="flexOne">
          <p className="locusLabel"> {suggestion.Name}</p>
        </div>
        <span
          className="width60 font14 lightGreyText latoBold appendBottom5 appendLeft10 textRight"
          style={{ width: "100px", padding: "0px" }}
        >
          {suggestion.AreaType}
        </span>
      </div>
    </div>
  );

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div {...containerProps}>
        <div class="react-autosuggest__section-title">
          <label class="hsw_sectionTitle font12 latoBlack greyText">
          Suggestions{" "}
          </label>
        </div>
        {/* <div> {children}</div> */}
        {query.length === 0 ? (
            <div>
                {/* <p class="hsw_sectionTitle font12 latoBlack greyText">Popular</p> */}
        <ul role="listbox" class="react-autosuggest__suggestions-list">
        <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div class="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionAccomodation("Seychelles")}>
              <div class="hsFlexspaceBetween hsFlex hrtlCenter">
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
              <div className="flexOne">
                <p className="locusLabel">Seychelles</p>
                </div>
              </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div class="font14 darkText  noChild  clickable" onClick={() => this.handleChangeSuggetionAccomodation("Mahe Island")}>
              <div class="hsFlexspaceBetween hsFlex hrtlCenter">
                <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
              <div className="flexOne">
                <p className="locusLabel">Mahe Island</p>
                </div>
              </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div class="font14 darkText  noChild  clickable"  onClick={() => this.handleChangeSuggetionAccomodation("Praslin Island")}>
              <div class="hsFlexspaceBetween hsFlex hrtlCenter">
              <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
              <div className="flexOne">
                <p className="locusLabel">Praslin Island</p>
                </div>
              </div>
            </div>
          </li>
          <li
            role="option"
            id="react-autowhatever-1-section-1-item-0"
            aria-selected="false"
            class="react-autosuggest__suggestion react-autosuggest__suggestion--first"
            data-section-index="1"
            data-suggestion-index="0"
          >
            <div class="font14 darkText  noChild  clickable"  onClick={() => this.handleChangeSuggetionAccomodation("La Digue Island")}>
              <div class="hsFlexspaceBetween hsFlex hrtlCenter">
              <div className="locationImageLocation">
                    <img
                    src="images/locationacco.png"
                    className="locationImageContent"
                    />
                </div>
              <div className="flexOne">
                <p className="locusLabel">La Digue Island</p>
                </div>
              </div>
            </div>
          </li>
          
        </ul></div>
         ) : (
            <div> {children}</div>
          )}
      </div>
    );
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      defaultZoneval: newValue,
    });
  };

  handleChangeSuggetionAccomodation = (newValue) => {
    this.setState({
      value: newValue,
      defaultZoneval: newValue,
    });
    this.setState({
        showResults: false,
      });
  };
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = () => {
    this.setState({
      showResults: false,
    });
  };

  showZonelistdiv = (syntheticEvent) => {
    this.setState({ dropdownVisible: false,  sorting: false });
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ showResults: !prevState.showResults }),
      () => {
        if (this.state.showResults) {
       
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
  };
  addRoom = (e) => {
    const catLength = this.state.cats.length;
    var i = 0;
    for (i = 0; i <= catLength - 1; i++) {
      let cats = [...this.state.cats];
      cats[i]["isExpanded"] = false;
      this.setState({
        cats: cats,
      });
    }

    this.setState((prevState) => ({
      cats: [
        ...prevState.cats,
        { adultCounter: 1, childCounter: 0, roomCounter: 1, isExpanded: true },
      ],
    }));
  };

  handleEditRoomClick = (index) => {
    const catLength = this.state.cats.length;
    var i = 0;
    for (i = 0; i <= catLength - 1; i++) {
      let cats = [...this.state.cats];
      cats[i]["isExpanded"] = false;
      this.setState({
        cats: cats,
      });
    }

    let cater = [...this.state.cats];
    cater[index]["isExpanded"] = true;
    this.setState({
      cats: cater,
    });
  };

  applyRoom = (e) => {
    this.setState(
      { dropdownVisible: false, showResults: false, checkinShow: false },
      () => {
      
        window.removeEventListener("click", this.globalClickListener);
      }
    );
  };
  handleSortingChange = (e) => {
    this.setState({
      hotelList: null,
    });

    const sorArr = this.state.hotelList;
    if (e.id == 2) {
      setTimeout(() => {
        const getValue = (price) => -price;
        sorArr.sort(
          (a, b) =>
            getValue(
              a.details.property_source == 1
                ? a.ejuniper.NewAmount == "0.00"
                  ? a.ejuniper.HotelOfferAmount
                  : a.ejuniper.NewAmount
                : a.details.property_source == 2
                ? a.hotellink.amount
                : a.hs_portal.amount
            ) -
            getValue(
              b.details.property_source == 1
                ? b.ejuniper.NewAmount == "0.00"
                  ? b.ejuniper.HotelOfferAmount
                  : b.ejuniper.NewAmount
                : b.details.property_source == 2
                ? b.hotellink.amount
                : b.hs_portal.amount
            )
        );



        this.setState({
          hotelList: sorArr,
        });
      }, 2000);
    } else if (e.id == 3) {
      setTimeout(() => {
        const getValue = (star) => -star;
        sorArr.sort(
          (a, b) =>
            getValue(
              a.details.star_category_id > 5 ? "0" : a.details.star_category_id
            ) -
            getValue(
              b.details.star_category_id > 5 ? "0" : b.details.star_category_id
            )
        );

  
        this.setState({
          hotelList: sorArr,
        });
      }, 2000);
    } else if (e.id == 1) {
      setTimeout(() => {
        if (sorArr[0].matched) {
          const sliceArr = sorArr.shift();
          const getValue = (price) => +price;
          sorArr.sort(
            (a, b) =>
              getValue(
                a.details.property_source == 1
                  ? a.ejuniper.NewAmount == "0.00"
                    ? a.ejuniper.HotelOfferAmount
                    : a.ejuniper.NewAmount
                  : a.details.property_source == 2
                  ? a.hotellink.amount
                  : a.hs_portal.amount
              ) -
              getValue(
                b.details.property_source == 1
                  ? b.ejuniper.NewAmount == "0.00"
                    ? b.ejuniper.HotelOfferAmount
                    : b.ejuniper.NewAmount
                  : b.details.property_source == 2
                  ? b.hotellink.amount
                  : b.hs_portal.amount
              )
          );

        

       

          const newArr = sorArr.splice(0, 0, sliceArr);

     

          this.setState({
            hotelList: sorArr,
          });
        } else {
          const getValue = (price) => +price;
          sorArr.sort(
            (a, b) =>
              getValue(
                a.details.property_source == 1
                  ? a.ejuniper.NewAmount == "0.00"
                    ? a.ejuniper.HotelOfferAmount
                    : a.ejuniper.NewAmount
                  : a.details.property_source == 2
                  ? a.hotellink.amount
                  : a.hs_portal.amount
              ) -
              getValue(
                b.details.property_source == 1
                  ? b.ejuniper.NewAmount == "0.00"
                    ? b.ejuniper.HotelOfferAmount
                    : b.ejuniper.NewAmount
                  : b.details.property_source == 2
                  ? b.hotellink.amount
                  : b.hs_portal.amount
              )
          );

        

          this.setState({
            hotelList: sorArr,
          });
        }
      }, 2000);
    }else if(e.id == 4){
      //const getValue = (id) => id;
      // sorArr.sort(
      //   (a, b) =>
      //     getValue(
      //       Object.values(a).map((i, j) => {
      //         return i.tour_data.id;
      //       })
      //     ) -
      //     getValue(
      //       Object.values(b).map((i, j) => {
      //         return i.tour_data.id;
      //       })
      //     )
      // );
      setTimeout(() => {
      const getValue = (id) => id;
      sorArr.sort(
        (a, b) =>
          getValue(
            a.details.id
          ) -
          getValue(
            b.details.id
          )
      );


      this.setState({
        hotelList: sorArr,
      });
          }, 2000)

    }

    this.setState({
      sortingSelect: e.name,
    });

    this.setState({
      sortingId: e.id,
    });
  };

  handleInputChange = (e, index) => {
    const { adultCounter, value } = e;
    let cats = [...this.state.cats];

    cats[index]["adultCounter"] = e;
    this.setState({
      cats: cats,
    });
  };

  handleInputChangeChild = (e, index) => {
    let cats = [...this.state.cats];
    cats[index]["childCounter"] = e;
    this.setState({
      cats: cats,
    });
  };
  handleRemoveClick = (index) => {
    let cats = [...this.state.cats];
    cats.splice(index, 1);
    this.setState({
      cats: cats,
    });
  };

  CheckAppliedFilter = () => {
   
    this.setState({ hotelList: null });

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      // let applied = [...this.state.appliedFilterVal];

      // let index = applied.length - 1;

      // applied.splice(index, 1);

      // this.setState({
      //   appliedFilterVal: applied,
      // });
      const name = target.name;
      let applied = [...this.state.appliedFilterVal];
      const index = applied.findIndex((x) => x.label === name);
      // Remove the filter if it exists
      applied.splice(index, 1);
      this.setState({
        appliedFilterVal: applied,
      });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const hotelData = [...this.state.hotelListFilter];

    const result = [];

    setTimeout(() => {
      hotelData.map((i, j) => {
        this.state.appliedFilterVal.map((item, index) => {
          if (this.state.defaultZoneval.toLowerCase().trim() === 'seychelles') {
              if (i.details.island.toLowerCase().trim() === item.label) {
                result.push(i);
              }
          } else {
              if (i.details.city.toLowerCase().trim() === item.label) {
                result.push(i);
              }
          }
        });
      });
    }, 1000);

    setTimeout(() => {
      this.setState({ hotelList: result });
    }, 1000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ hotelList: hotelData });
      }
    }, 1000);
  };

  CheckAppliedFilterProperty = () => {
  
    this.setState({ hotelList: null });

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
   
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      // let applied = [...this.state.appliedFilterVal];

      // let index = applied.length - 1;

      // applied.splice(index, 1);

      // this.setState({
      //   appliedFilterVal: applied,
      // });
      const name = target.name;
      let applied = [...this.state.appliedFilterVal];
      const index = applied.findIndex((x) => x.label === name);
      // Remove the filter if it exists
      applied.splice(index, 1);
      this.setState({
        appliedFilterVal: applied,
      });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const hotelData = [...this.state.hotelListFilter];

    const result = [];

    setTimeout(() => {
      hotelData.map((i, j) => {
        this.state.appliedFilterVal.map((item, index) => {
          if (i.details.property_type.toLowerCase().trim() === item.label) {
            result.push(i);
          }
        });
      });
    }, 1000);

    // const result = hotelData.filter(data => (data.details.city.toLowerCase()).trim() == name);

   

    setTimeout(() => {
      this.setState({ hotelList: result });
    }, 1000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ hotelList: hotelData });
      }
    }, 1000);
  };

  RemoveSingleFilter = (i, index, maxValue) => {
 
    this.setState({ hotelList: null });

    let applied = [...this.state.appliedFilterVal];

    applied.splice(index, 1);

    this.setState({
      appliedFilterVal: applied,
    });

    document.getElementsByName(i.label).checked = false;

    this.setState((prevState) => prevState.checkedItems.set(i.label, false));

    let PriceRange = { min: 0, max: maxValue };

    this.setState({ valuePrice: PriceRange });

    const hotelData = [...this.state.hotelListFilter];

    const result = [];

    setTimeout(() => {
      hotelData.map((i, j) => {
        if (this.state.appliedFilterVal.length > 0) {
          this.state.appliedFilterVal.map((item, index) => {
            if (this.state.defaultZoneval.toLowerCase().trim() === 'seychelles') {
              if (i.details.island.toLowerCase().trim() === item.label) {
                result.push(i);
              }
          } else {
              if (i.details.city.toLowerCase().trim() === item.label) {
                result.push(i);
              }
          }
          });
        }
      });
    }, 1000);
    setTimeout(() => {
      hotelData.map((i, j) => {
        this.state.appliedFilterVal.map((item, index) => {
          if (i.details.property_type.toLowerCase().trim() === item.label) {
            result.push(i);
          }
        });
      });
    }, 1000);

    // const result = hotelData.filter(data => (data.details.city.toLowerCase()).trim() == name);

 

    setTimeout(() => {
     this.setState({ hotelList: result });
    }, 1000);
    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ hotelList: hotelData });
        this.setState({
          SkeletonData: false,
        });
      }
    }, 1000);
  };

  RemoveMultipleFilter = (maxValue) => {
    this.setState({
      appliedFilterVal: [],
    });
    this.setState({ hotelList: null });
    let PriceRange = { min: 0, max: maxValue };

    this.setState({ valuePrice: PriceRange });

    const clearCheckedItems = new Map();
    this.setState({ checkedItems: clearCheckedItems });

    const hotelData = [...this.state.hotelListFilter];

    setTimeout(() => {
      this.setState({ hotelList: hotelData });
    }, 1000);
  };

  changePrice = (value) => {
    this.setState({ hotelList: null });
 
    //  this.setState({ valuePrice: value })

    let name = value.min + "-" + value.max;
    let applied = [...this.state.appliedFilterVal];

    let catlength = applied.length;
    let PriceRangeVal = "abc";
    //var i = 0;

    applied.map((i, j) => {
      if (i.value == "priceRange") {
        PriceRangeVal = j;
        return PriceRangeVal;
      }
    });

   
    if (PriceRangeVal == "abc") {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: "priceRange" }],
      });
    } else {
      applied[PriceRangeVal] = { label: name, value: "priceRange" };
      this.setState({
        appliedFilterVal: applied,
      });
    }

    const hotelData = [...this.state.hotelListFilter];

    const result = [];

    setTimeout(() => {
      hotelData.map((i, j) => {
        if (i.details.property_source == 1) {
          if (i.ejuniper.Amount != "0.00") {
            if (
              parseInt(i.ejuniper.Amount) >= value.min &&
              parseInt(i.ejuniper.Amount) <= value.max
            ) {
              result.push(i);
            }
          } else if (i.ejuniper.NewAmount != "0.00") {
            if (
              parseInt(i.ejuniper.NewAmount) >= value.min &&
              parseInt(i.ejuniper.NewAmount) <= value.max
            ) {
              result.push(i);
            }
          }
        } else if (i.details.property_source == 2) {
          if (i.hotellink.amount > 0) {
            if (
              parseInt(i.hotellink.amount) >= value.min &&
              parseInt(i.hotellink.amount) <= value.max
            ) {
              result.push(i);
            }
          }
        }
      });
    }, 1000);


    setTimeout(() => {
      this.setState({ hotelList: result });
    }, 1000);
  };

  
  render() {
    const menuclassName = `panel-dropdown${
      this.state.dropdownVisible ? " active" : ""
    }`;
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "Enter city/ Hotel/ Area/ Building",
      type: "search",
      value,
      onChange: this.onChange,
    };
   // console.log(this.state.hotelList);
    return (
      <React.Fragment>
        <div>
        <Helmet>
      <title>Book Best Hotels in seychelles- Holidays Seychelles</title>
        <meta name="description" content="Get best price deals for Seychelles, one of the best place for honeymoon in the world." />
        <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
      </Helmet>
      <h1 style={{color:'#fff'}}>Book Best Hotels in seychelles- Holidays Seychelles</h1>
          <NewHeader page={"inner_listing"} tabName={1} />
          <div
            className={`${
              this.state.nav == true
                ? "_Hlisting_header_new"
                : "_Hlisting_header"
            }`}
          >
          
            <div className="container hsw">
              <div className="hsw_inner">
                <div className="hsw_inputBox  " onClick={this.showZonelistdiv}>
                  <label
                    for="city"
                    className="lbl_input latoBold font12 blueText"
                  >
                    City, Location or Property
                  </label>
                  <p
                    className="hsw_inputField font16 whiteText"
                    style={{ marginBottom: "3px" }}
                  >
                    {this.state.defaultZoneval.length >= 40
                      ? `${this.state.defaultZoneval.substring(0, 40)}...`
                      : this.state.defaultZoneval}
                  </p>
                  <div className="focused_div">
                    {this.state.showResults ? (
                      <div
                        className="autocomplete-wrapper"
                        onClick={this.handleBodyClick}
                      >
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionSelected={this.onSuggestionSelected}
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          renderSuggestionsContainer={
                            this.renderSuggestionsContainer
                          }
                          inputProps={inputProps}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="hsw_inputBox">
                  <span className="checkinandcheckout">
                    <label
                      for="checkin"
                      className="lbl_input latoBold font12 blueText capText"
                    >
                      Check-In:
                    </label>
                    <label
                      for="checkout"
                      className="lbl_input latoBold font12 blueText capText"
                    >
                      Check-Out:
                    </label>
                  </span>
                
                  <RangeDatePicker
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={(startDate, endDate) =>
                      this.setState({ startDate: startDate, endDate: endDate })
                    }
                    minDate={new Date()}
                    maxDate={new Date(2100, 0, 1)}
                    startDatePlaceholder="Check In"
                    endDatePlaceholder="Check Out"
                    className="hotellisting"
                    startWeekDay="monday"
                  />
                </div>
                
                <div className="hsw_inputBox  " onClick={this.toggleDropdown}>
                  <label
                    for="guest"
                    className="lbl_input latoBold font12 blueText"
                  >
                    Rooms &amp; Guests
                  </label>

                  <div className={menuclassName}>
                    {this.state.dropdownVisible && this.renderDropdownMenu()}
                  </div>
                  <p
                    className="hsw_inputField guests font16 whiteText"
                    style={{ marginBottom: "0px" }}
                  >
                    {this.state.cats.reduce((a, v) => a + v.roomCounter, 0)}
                    <span> Room</span>{" "}
                    {this.state.cats.reduce(
                      (a, v) => a + v.adultCounter * 1 + v.childCounter * 1,
                      0
                    )}
                    <span> Guests</span>
                  </p>
                </div>
                <span
                  className="primaryBtn hsw_searchButton"
                  onClick={this.SearchListing}
                >
                  Search
                </span>
              </div>
            </div>
          </div>
          <div
            className={`${
              this.state.nav == true
                ? "_Hlisting_header_viewMap_new"
                : "_Hlisting_header_viewMap"
            }`}
          >
           
            <div className="container makeFlex hsw_viewMap">
              <div className="hsw_viewMap_content" style={{ display: "flex" }}>
                <span class="latoBold blackText"> Sort By:&nbsp;</span>
                <div class="customSelect">
                  <span
                    class="customSelectTitle  latoBold"
                    style={{ color: "#008cff" }}
                    onClick={this.showSorting}
                  >
                    {this.state.sortingSelect}
                  </span>
                  {this.state.sorting && (
                    <ul class="customSelectOptions latoBold">
                      {SortArr.map((item, index) => (
                        <li
                          className={
                            this.state.sortingSelect == item.name
                              ? "selectedActiveSort"
                              : ""
                          }
                          key={index}
                          onClick={() => this.handleSortingChange(item)}
                        >
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <span style={{ fontSize: "16px" }}>
                  <b>|</b> Showing{" "}
                  <b>{this.state.hotelList == null
                    ? " "
                    : this.state.hotelList == "nohotel"
                    ? " "
                    : this.state.hotelList.length}{" "}</b>
                  properties in {this.state.defaultZoneval}
                </span>
              </div>
           
             
            </div>
          </div>
          <div className="container makeFlex spaceBetween">
            <div
              className="container makeFlex spaceBetween"
              style={{ padding: "30px" }}
            >
              {this.state.FiltersData == null ? (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    <p className="makeFlex spaceBetween end">
                      <span className="latoBold font20 blackText">
                        <Skeleton style={{ width: 240, height: 30 }} />
                      </span>
                    </p>

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        <Skeleton style={{ width: 240, height: 26 }} />
                      </div>
                      <ul className="filterList">
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="filterRow" id="hlistpg_fr_star_category">
                    <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                      <Skeleton style={{ width: 240, height: 26 }} />
                    </div>
                    <ul className="filterList">
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                    </ul>
                  </div>

                  <div className="filterRow" id="hlistpg_fr_star_category">
                    <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                      <Skeleton style={{ width: 240, height: 26 }} />
                    </div>
                    <ul className="filterList">
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : this.state.FiltersData == "no-Filter" ? (
                <> </>
              ) : (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    {this.state.appliedFilterVal.length > 0 ? (
                      <div className="appliedFiltersContainer">
                        <div
                          className="makeFlex spaceBetween end appendBottom20"
                          style={{
                            marginBottom: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="font20 blackText">
                            Applied Filters
                          </span>
                          <a
                            className="latoBold font12 capText"
                            href="javascript:;"
                            onClick={() =>
                              this.RemoveMultipleFilter(
                                this.state.FiltersData.budget.maxPrice
                              )
                            }
                          >
                            Clear
                          </a>
                        </div>
                        <ul className="appliedFilters">
                          {this.state.appliedFilterVal.map((i, j) => (
                            <li>
                              <span className="latoBold" style={{textTransform: 'capitalize'}}>{i.label}</span>
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  this.RemoveSingleFilter(
                                    i,
                                    j,
                                    this.state.FiltersData.budget.maxPrice
                                  )
                                }
                              ></a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p
                        className="makeFlex spaceBetween end"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <span
                          className="font20 blackText"
                          style={{ fontWeight: 500 }}
                        >
                          Select Filters
                        </span>
                      </p>
                    )}

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div
                        className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter"
                        style={{ marginTop: 15 }}
                      >
                        Location
                      </div>
                      <ul className="filterList">
                        {Object.values(this.state.FiltersData.location).sort((a, b) => parseFloat(b.count) - parseFloat(a.count)).map(
                          (i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilter}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.hotelList == null ? true : false
                                  }
                                />
                                <span className="cat_star" style={{textTransform:'capitalize'}}>
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score">
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="filterRow" id="hlistpg_fr_star_category">
                    <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                      Property Type
                    </div>
                    <ul className="filterList">
                      {Object.values(this.state.FiltersData.property_type).sort((a, b) => parseFloat(b.count) - parseFloat(a.count)).map(
                        (i, j) => (
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                className="icheck"
                                onChange={this.CheckAppliedFilterProperty}
                                name={i.filter_label}
                                checked={
                                  !!this.state.checkedItems.get(i.filter_label)
                                }
                                disabled={
                                  this.state.hotelList == null ? true : false
                                }
                              />
                              <span className="cat_star" style={{textTransform:'capitalize'}}>{i.filter_label}</span>{" "}
                              <small className="star_score">({i.count})</small>{" "}
                            </label>
                          </li>
                        )
                      )}
                    </ul>
                  </div>

                  <div
                    className="filterRow"
                    id="hlistpg_fr_star_category"
                    style={{ marginBottom: 30 }}
                  >
                    <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                      Price Per Night
                    </div>
                    <InputRange
                      maxValue={this.state.FiltersData.budget.maxPrice}
                      minValue={0}
                      value={this.state.valuePrice}
                      onChange={(value) => this.setState({ valuePrice: value })}
                      onChangeComplete={(value) => this.changePrice(value)}
                    />
                    <span className="minValue">
                      <FontAwesomeIcon
                        icon={faEuroSign}
                        style={{ fontSize: "13px", color: "#555" }}
                      ></FontAwesomeIcon>{" "}
                      {this.state.valuePrice.min}
                    </span>
                    <span className="maxValue">
                      <FontAwesomeIcon
                        icon={faEuroSign}
                        style={{ fontSize: "13px", color: "#555" }}
                      ></FontAwesomeIcon>{" "}
                      {this.state.valuePrice.max}
                    </span>
                  </div>
                </div>
              )}

              <div className="listingWrap">
                <div className="container container-custom margin_30_95">
               
                  {this.state.hotelList === null ? (
                    <>
                      <HotelListSkeleton />
                      <HotelListSkeleton />
                      <HotelListSkeleton />
                    </>
                  ) : this.state.hotelList == "nohotel" ? (
                    <AccommodationSoldout/>
                  ) : (
                    <>
                      {this.state.hotelList.map((item, key) => (
                        <HotelList
                          details={item.details}
                          gallery={item.property_gallery}
                          services={item.property_services_facilities}
                          special_off={item.property_special_offers}
                          juniper={item.ejuniper}
                          hotellink={item.hotellink}
                          searchData={this.state.searchData}
                          hsPortal={item.hs_portal}
                          match={item.matched}
                          SupplierUrl={SupplierUrl}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div >

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={this.customStyles}
            contentLabel="Example Modal"
          >
            <div className="listingModal">
              <div className="listingModalLeft">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4 className="Listtext">Hotels</h4>
                  <span className="CloseModal" onClick={this.closeModal}>
                    Close
                  </span>
                </div>
                <List
                  width={320}
                  height={570}
                  rowCount={
                    this.state.hotelList == null
                      ? 0
                      : this.state.hotelList.length
                  }
                  rowHeight={137}
                  rowRenderer={this.rowRenderer}
                  overscanRowCount={3}
                />
                ,
              </div>
              <div className="listingModalRight">
                <div id="Maps" className="google-map">
                  <GoogleMap
                    selectedPlace={this.state.SelectedPlace}
                    data={this.state.hotelList}
                  />
                </div>
              </div>
            </div>
          </Modal>
          <div
          ref={loadingRef => (this.loadingRef = loadingRef)}
        >
          {/* <span>Loading...</span> */}
        </div>
        </div>
        <TawkToWidget />
      </React.Fragment>
    );
  }
}
