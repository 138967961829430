import React, { Component } from "react";
import Banner from "./Banner";
import BannerTabNav from "./BannerTabNav";
import Superoffers from "./Superoffers";
import Event from "./Events/Event";
import Footer from "./Footer";
import "./Header.css";
import { Link, Redirect } from "react-router-dom";
import { Base64 } from "js-base64";
import {
  setCustomerLogin,
  getGeoInfo,
  getCustomerLogin,
  LoginConfirmation,
  ConfirmationForgot,
  HomePageEventData,
  getSpecialOffersForHome,
} from "./axios";
import Modal from "react-modal";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import moment from "moment";
import SignUp from "./SignUp";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import SkeletonHome from "./SkeletonHome";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const TabsArr = [
  "Accommodations",
  "Attractions & Things to Do",
  "Packages",
  "Land & Ferry Transfers",
  "Liveaboard",
];

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
class Header extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      nav: false,
      selected: "Accommodations",
      modalIsOpen: false,
      ChangeLoginScreenClass: "containerLogin",
      ChangeForgotScreenClass: "containerForget",
      facebookLogin: false,
      facebookResponse: {},
      GoogleLoginButton: false,
      GoogleResponse: {},
      country: "",
      country_code: "",
      currency: "",
      ip_address: "",
      customerEmail: cookies.get("userDetails") || "",
      customerEmailForData: localStorage.getItem("userDetails") || "abc",
      customerDetails: {},
      profileNew: false,
      profileNewAgain: false,
      LoginEmail: "",
      LoginPassword: "",
      errorPassword: false,
      OfferData: null,
      modalIsSecondOpen: false,
      ForgotEmail: "",
      errorForgotEmail: false,
      EmailError: false,
      PasswordLoginError:false,
      ForgotEmailError:false
    };
  }

  handleScroll = () => {
    if (window.pageYOffset > 40) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }
  };
  setSelectedTabOptions = (tab) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.setState({ selected: tab });
  };

  ChangeLoginEmail = (event) => {
   const email = validateEmail(event.target.value);
   if (email == true) {
    this.setState({ LoginEmail: event.target.value });
     this.setState({ EmailError: false });
   } else {
     this.setState({ LoginEmail: event.target.value });
    this.setState({ EmailError: true });
   }
  };

  ChangeLoginPassword = (event) => {
    //this.setState({ LoginPassword: event.target.value });
    const password = event.target.value;
    if (password.length > 5 && password.length < 15) {
      this.setState({ LoginPassword: event.target.value });
      this.setState({ PasswordLoginError: false });
    } else {
      this.setState({ LoginPassword: event.target.value });
      this.setState({ PasswordLoginError: true });
    }
  };

  LoginConfirm = (e) => {
    e.preventDefault();
    let CheckEmailError = false;
    let CheckPasswordLoginError = false;
    this.setState({ EmailError: false });
    this.setState({ PasswordLoginError: false });
    this.setState({ errorPassword: false });
     let emailNew = this.state.LoginEmail;
   
    const email = validateEmail(emailNew);
    let emailNewData ='';
    if (email == true) {
      emailNewData = emailNew;
      this.setState({ EmailError: false });
      CheckEmailError = true;
    } else {
      CheckEmailError = false;
      this.setState({ EmailError: true });
    }

    let password = this.state.LoginPassword;
 
    if (password.length > 5 && password.length < 15) {
      CheckPasswordLoginError = true;
     
     this.setState({ PasswordLoginError: false });
    } else {
      CheckPasswordLoginError = false;
     
      this.setState({ PasswordLoginError: true });
    }
    if (
      CheckEmailError == true &&
      CheckPasswordLoginError == true 
    ) {
    
    LoginConfirmation(emailNewData, password).then(
      (data) => {
        // console.log(data);
        if (data == "not_correct") {
          this.setState({ errorPassword: true });
        } else {
          this.setState({ customerDetails: data });

          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
          window.location.reload(true);
        }
      },
      (error) => {}
    );
    }

  };

  //Ranjeet BY
  ChangeForgotEmail = (event) => {
  
    const email = validateEmail(event.target.value);
    if (email == true) {
     this.setState({ ForgotEmail: event.target.value });
      this.setState({ ForgotEmailError: false });
    } else {
      this.setState({ ForgotEmail: event.target.value });
     this.setState({ ForgotEmailError: true });
    }
  };

  ForgotConfirm = (e) => {
    e.preventDefault();
    let CheckEmailError = false;
    let emailNew = this.state.ForgotEmail;
   
    const email = validateEmail(emailNew);
    let emailNewData ='';
    if (email == true) {
      emailNewData = emailNew;
      this.setState({ ForgotEmailError: false });
      CheckEmailError = true;
    } else {
      CheckEmailError = false;
      this.setState({ ForgotEmailError: true });
    }
    // let emailNew = this.state.ForgotEmail;
    // if (validateEmail(emailNew)) {
    //   let email = "";
    //   if (emailNew.length > 0) {
    //     email = emailNew;
    //   }
      // let password = this.state.LoginPassword;

      if (CheckEmailError == true ) {

      ConfirmationForgot(emailNewData).then(
        (data) => {
          // console.log(data);
          if (data == "not_correct") {
            this.setState({ errorForgotEmail: true });
          } else {
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          }
        },
        (error) => {}
      );
    } 
  };

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tabName = urlParams.get("t");
    const logout = urlParams.get("lg");
    if(logout !=null){
      if (Base64.atob(logout) == 'logout') {
        localStorage.clear();
         var base_url = window.location.origin;
         window.location.href = base_url;
      }
    }
    

    if (tabName != null) {
      if (Base64.atob(tabName) == 1) {
        this.setState({ selected: "Accommodations" });
      } else if (Base64.atob(tabName) == 2) {
        this.setState({ selected: "Attractions & Things to Do" });
      } else if (Base64.atob(tabName) == 3) {
        this.setState({ selected: "Packages" });
      } else if (Base64.atob(tabName) == 4) {
        this.setState({ selected: "Land & Ferry Transfers" });
      } else if (Base64.atob(tabName) == 5) {
        this.setState({ selected: "Liveaboard" });
      }
    }

    let userData = localStorage.getItem("userDetails");
    getGeoInfo().then(
      (data) => {
        // console.log(data);
        this.setState({ country: data.country_name });
        this.setState({ country_code: data.country_calling_code });
        this.setState({ currency: data.currency });
        this.setState({ ip_address: data.ip });
      },
      (error) => {}
    );

    if (userData != null) {
      const { cookies } = this.props;

      let newData = JSON.parse(userData);
      getCustomerLogin(newData.email).then(
        (data) => {
          //   console.log(data);
          this.setState({ customerDetails: data });

          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
        },
        (error) => {}
      );
      
    }

    getSpecialOffersForHome().then(
      (data) => {
        // console.log(data);
        this.setState({ OfferData: data });
      },
      (error) => {}
    );

    window.addEventListener("scroll", this.handleScroll);
  }

  ChangeLoginScreen = () => {
    this.setState({
      ChangeLoginScreenClass: "containerLogin right-panel-active",
    });
  };
  ChangeLoginReverse = () => {
    this.setState({ ChangeLoginScreenClass: "containerLogin" });
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("click", this.globalClickListener);
  }

  globalClickListener = (nativeEvent) => {
    this.setState({ profileNew: false, profileNewAgain: false }, () => {
      // console.log("global click");
      // document.removeEventListener('click', this.globalClickListener)
      window.removeEventListener("click", this.globalClickListener);
    });
  };
  OpenProfilelink = (syntheticEvent) => {
    // console.log("toggle dropdown");
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ profileNew: !prevState.profileNew }),
      () => {
        if (this.state.profileNew) {
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    // console.log(this.state.dropdownVisible);
  };
  OpenProfilelinkAgain = (syntheticEvent) => {
    syntheticEvent.stopPropagation();
    this.setState(
      (prevState) => ({ profileNewAgain: !prevState.profileNewAgain }),
      () => {
        if (this.state.profileNewAgain) {
         
          // document.addEventListener('click', this.globalClickListener)
          window.addEventListener("click", this.globalClickListener);
        }
      }
    );
    // console.log(this.state.dropdownVisible);
  };

  openModal = () => {
    this.setState({ modalIsSecondOpen: false });
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openSecondModal = () => {
    this.setState({ modalIsOpen: false });
    this.setState({ modalIsSecondOpen: true });
  };

  closeSecondModal = () => {
    this.setState({ modalIsSecondOpen: false });
  };

  LoginFaceBook = () => {
    this.setState({ facebookLogin: true });
  };

  GoogleLoginOpen = () => {
    this.setState({ GoogleLoginButton: true });
  };

  addResponseData = (response) => {
    this.setState({ facebookResponse: response });
  };

  responseFacebook = (response) => {
    const { cookies } = this.props;
   //console.log(response);
    if (response.accessToken) {
      this.setState({ facebookResponse: response });
      let Name = response.first_name;

      let first_name = response.first_name;
      let last_name = response.last_name;
      let email = response.email;
      let profile_image = response.picture.data.url;
      let birthday = moment(response.birthday).format("Y-MM-DD");
      let gender = response.gender;
      let login_source = 1;

      let mobile_number = "";
      let country = this.state.country;
      let country_code = this.state.country_code;
      let currency = this.state.currency;
      let ip_address = this.state.ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      cookies.set("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country,
        country_code,
        currency,
        ip_address,
        password,
        verified
      ).then(
        (data) => {
          //  console.log(data);
          if (data.id > 0) {
            window.location.reload(true);
          }
        },
        (error) => {}
      );
      this.setState({ modalIsOpen: false });
     // window.location.reload(true);
    }
  };

  responseGoogle = (response) => {
    // console.log(response);
    if (response.accessToken) {
      const { cookies } = this.props;
      this.setState({ GoogleResponse: response });

      let first_name = response.profileObj.givenName;
      let last_name = response.profileObj.familyName;
      let email = response.profileObj.email;
      let profile_image = response.profileObj.imageUrl;
      let birthday = "";
      let gender = "";
      let login_source = 2;

      let mobile_number = "";
      let country = this.state.country;
      let country_code = this.state.country_code;
      let currency = this.state.currency;
      let ip_address = this.state.ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      cookies.set("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country,
        country_code,
        currency,
        ip_address,
        password,
        verified
      ).then(
        (data) => {
          // console.log(data);
          if (data.id > 0) {
            window.location.reload(true);
          }
        },
        (error) => {}
      );
      this.setState({ modalIsOpen: false });
      // window.location.reload(true);
    }
  };

  logoutChange = () => {
    localStorage.clear();
    window.location.reload(true);
  };

  render() {
  
    return (
      <div id="page" className="bgGradient">
        <div className="mobile-menu">
          <nav className="navbar navbar-expand-md navbar-light mobile-menu">
            <button className="ma5menu__toggle" type="button">
              <span class="ma5menu__icon-toggle"></span>{" "}
              <span class="ma5menu__sr-only">Menu</span>
            </button>
            <div>
              <ul className="site-menu" style={{ display: "none" }}>
                <li>
                  <a href="" title="Home">
                    Home
                  </a>
                </li>
                <li>
                  <a href="contact-us.html" title="Contact Us">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                <div className="logo">
                  <a className="" href="/">
                    <img
                      src="images/logo/seyLogo.png"
                      alt="Holiday Seychelles"
                    />
                  </a>
                </div>
              </div>

              <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                <div className="menu-top">
                  <ul className="float-left">
                    <Link to="/tourofferlisting">
                      <li className="hsFlex_Bann hsCenter hsSupport">
                        <span className="landingSprite supportIcon appendRight10">
                          &nbsp;
                        </span>
                        <div className="hsFlex_Bann column flexOne font10 latoBold">
                          <p className="hs-white appendBottom3_Header">
                            Offers
                          </p>
                          <p
                            className="hs-white appendBottom3_Header"
                            style={{ fontSize: 9 }}
                          >
                            Explore Best Deals and Offers
                          </p>
                        </div>
                      </li>
                    </Link>
                    <a
                      href="https://agents.holidays-seychelles.com/"
                      target="_blank"
                    >
                      <li className="hsFlex_Bann hsCenter font10 hsRelative">
                        <div className="hsFlex_Bann column flexOne hs-white latoBold">
                          <p
                            className="hs-white appendBottom3_Header"
                            style={{ fontWeight: 700 }}
                          >
                            HS for Agent
                          </p>
                          <p
                            className="hs-white appendBottom3_Header"
                            style={{ fontSize: 9 }}
                          >
                            ( Agent Login )
                          </p>
                        </div>
                      </li>
                    </a>
                    <a
                      href="https://supplier.holidays-seychelles.com/"
                      target="_blank"
                    >
                      <li className="hsFlex_Bann hsCenter font10 hsRelative">
                        <div className="hsFlex_Bann column flexOne hs-white latoBold">
                          <p
                            className="hs-white appendBottom3_Header"
                            style={{ fontWeight: 700 }}
                          >
                            HS for Business
                          </p>
                          <p
                            className="hs-white appendBottom3_Header"
                            style={{ fontSize: 9 }}
                          >
                            ( Supplier Login )
                          </p>
                        </div>
                      </li>
                    </a>
                    {this.state.customerEmailForData == "abc" ? (
                      <>
                        {/* <li
                          className="hsFlex_Bann hsCenter hsSupport"
                          onClick={this.openModal}
                        >
                          <span className="landingSprite myTripsIcon appendRight10">
                            &nbsp;
                          </span>
                          <div className="hsFlex_Bann column flexOne font10 latoBold">
                            <p className="hs-white appendBottom3_Header">
                              My Trip Plans
                            </p>
                            <p
                              className="hs-white appendBottom3_Header"
                              style={{ fontSize: 9 }}
                            >
                              Plan and Save Trips
                            </p>
                          </div>
                        </li> */}
                        <li
                          className="hsFlex_Bann hsCenter hsSupport"
                          onClick={this.openModal}
                        >
                          <span className="landingSprite myTripsIcon appendRight10">
                            &nbsp;
                          </span>
                          <div className="hsFlex_Bann column flexOne font10 latoBold">
                            <p className="hs-white appendBottom3_Header">
                              My Trips
                            </p>
                            <p
                              className="hs-white appendBottom3_Header"
                              style={{ fontSize: 9 }}
                            >
                              Plan Trips and Bookings
                            </p>
                          </div>
                        </li>
                      </>
                    ) : (
                      <>
                        <Link to="/PlanTrips">
                          <li className="hsFlex_Bann hsCenter hsSupport">
                            <span className="landingSprite myTripsIcon appendRight10">
                              &nbsp;
                            </span>
                            <div className="hsFlex_Bann column flexOne font10 latoBold">
                              <p className="hs-white appendBottom3_Header">
                              My Trips
                              </p>
                              <p
                                className="hs-white appendBottom3_Header"
                                style={{ fontSize: 9 }}
                              >
                                Plan Trips and Bookings
                              </p>
                            </div>
                          </li>
                        </Link>
                        {/* <Link to="/PlanTrips">
                          <li className="hsFlex_Bann hsCenter hsSupport">
                            <span className="landingSprite myTripsIcon appendRight10">
                              &nbsp;
                            </span>
                            <div className="hsFlex_Bann column flexOne font10 latoBold">
                              <p className="hs-white appendBottom3_Header">
                                My Booking
                              </p>
                              <p
                                className="hs-white appendBottom3_Header"
                                style={{ fontSize: 9 }}
                              >
                                Manage Your Bookings
                              </p>
                            </div>
                          </li>
                        </Link> */}
                      </>
                    )}

                    {/* <Link to="/travelblog" target="_blank">
                  <li className="hsFlex_Bann hsCenter hsSupport">
                    <img 
                     src="images/airplane.png"
                     className=" travel_blog appendRight10"/>
                    <div className="hsFlex_Bann column flexOne font10 latoBold">
                      <p className="hs-white appendBottom3_Header">
                        Travel Blog
                      </p>
                    </div>
                  </li>
                </Link> */}
                    {/* <Link to="/tourofferlisting" target="_blank">
                  <li className="hsFlex_Bann hsCenter hsSupport">
                    <span className="landingSprite supportIcon appendRight10">
                      &nbsp;
                    </span>
                    <div className="hsFlex_Bann column flexOne font10 latoBold">
                      <p className="hs-white appendBottom3_Header">Offers</p>
                      <p
                        className="hs-white appendBottom3_Header"
                        style={{ fontSize: 9 }}
                      >
                       Explore Deals and Offers
                      </p>
                    </div>
                  </li>
                </Link> */}

                    {/* 
                                {this.state.customerEmailForData == 'abc' ? (<li className="hsFlex_Bann hsCenter hsMyWallet" onClick={this.openModal}><span className="landingSprite walletBlanceIcon appendRight10">&nbsp;</span><span></span>
                                </li>
                                ) : (

                                    <Link to="/Wallet" target="_blank">
                                        <li className="hsFlex_Bann hsCenter hsMyWallet"><span className="landingSprite walletBlanceIcon appendRight10">&nbsp;</span><span></span>
                                        </li>
                                    </Link>
                                )} */}

                    {this.state.customerEmailForData == "abc" ? (
                      <li
                        className="hsFlex_Bann hsCenter font10 hsRelative hsUser full-with"
                        onClick={this.openModal}
                      >
                        <div className="hsFlex_Bann column flexOne hs-white latoBold">
                          <p className="hs-white appendBottom3_Header">
                            {" "}
                            <img
                              src="images/icon/favicon.ico"
                              className="loginIcon"
                            />{" "}
                            Login or Create Account
                          </p>
                        </div>
                      </li>
                    ) : (
                      <li
                        className="hs-LoginFlex hsCenter font10 hsRelative hsUser userLoggedIn"
                        onClick={this.OpenProfilelink}
                      >
                        {this.state.customerDetails == {} ? (
                          <span>
                            <Skeleton style={{ width: 200, height: 20 }} />
                          </span>
                        ) : (
                          <>
                            {this.state.customerDetails?.profile_pic == "" ? (
                              <span className="userNameIcon hs-white hs-LoginFlex perfectCenter latoBlack appendRight10">
                                <span>
                                  {this.state.customerDetails?.first_name?.charAt(
                                    0
                                  )}
                                </span>
                              </span>
                            ) : (
                              <span className="userNameIcon hs-white hs-LoginFlex perfectCenter latoBlack appendRight10">
                              <span>
                                {this.state.customerDetails?.first_name?.charAt(
                                  0
                                )}
                              </span>
                            </span>
                              // <span className="userNameImage  appendRight10">
                              //   <img
                              //     style={{
                              //       width: "100%",
                              //       borderRadius: "100%",
                              //     }}
                              //     src={this.state.customerDetails.profile_pic}
                              //   />
                              // </span>
                            )} 

                            <div className="hs-LoginFlex column userNameText  hs-white latoBold">
                              <p
                                data-cy="loggedInUser"
                                className="appendBottom3 truncate customerName"
                              >
                                Hey {this.state.customerDetails.first_name}
                              </p>
                            </div>
                          </>
                        )}

                        {this.state.profileNew && (
                          <div className="userDropdown fadeInDown hs-LoginFlex column">
                            <a
                              className="userItems hs-LoginFlex"
                              href="/PlanTrips"
                            >
                              <span className="landingSprite userItemMyTrips appendRight20"></span>
                              <div>
                                <p className="font16 latoBold blackText appendBottom5">
                                My Trips
                                </p>
                                <p className="font12 lineHeight16 blackText">
                                Plan Trips and Manage Your Bookings
                                </p>
                              </div>
                            </a>
                            {/* <a
                              className="userItems hs-LoginFlex"
                              href="/PlanTrips"
                            >
                              <span className="landingSprite userItemMyTrips appendRight20"></span>
                              <div>
                                <p className="font16 latoBold blackText appendBottom5">
                                  My Booking
                                </p>
                                <p className="font12 lineHeight16 blackText">
                                  See booking details, Cancel Booking &amp;
                                  more.
                                </p>
                              </div>
                            </a> */}
                            {/* <a
                          className="userItems hs-LoginFlex"
                          href="/ExpreriancePlanTrips"
                          target="_blank"
                        >
                          <span className="landingSprite userItemMyTrips appendRight20"></span>
                          <div>
                            <p className="font16 latoBold blackText appendBottom5">
                              My Expreriance Trips
                            </p>
                            <p className="font12 lineHeight16 blackText">
                              See booking details, expreriance planned your own trip, Cancel
                              Booking &amp; more.
                            </p>
                          </div>
                        </a> */}

                            {/* <a className="userItems hs-LoginFlex" href="/Wallet" target="_blank">
                                                    <span className="landingSprite userItemMyWallet appendRight20"></span>
                                                    <div>
                                                        <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">My Wallet
                                                <span className="myWalletTag font12 hs-white appendLeft5 latoBold"> ₹ 0</span>
                                                        </p>

                                                        <p className="font12 lineHeight16 blackText">Use your wallet money to avail even greater discounts</p>
                                                    </div></a> */}

                            <a
                              className="userItems hs-LoginFlex"
                              href="/Profile"
                            >
                              <span className="landingSprite userItemMyProfile appendRight20"></span>
                              <div>
                                <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">
                                  My Profile
                                </p>
                                <p className="font12 lineHeight16 blackText">
                                  Manage your profile, login details and
                                  password
                                </p>
                              </div>
                            </a>
                            <a
                              className="userItems hs-LoginFlex appendBottom10"
                              onClick={this.logoutChange}
                            >
                              <span className="landingSprite userItemLogout appendRight20"></span>
                              <div>
                                <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">
                                  Logout
                                </p>
                              </div>
                            </a>
                          </div>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="hsFlex_Bann hsCenter prependTop5 appendBottom15 mobile-view">
              {" "}
            </div>

            <div className="header-top-section">
              <div
                className={`chHeaderWrapper ${
                  this.state.nav == true ? " stickyHeader" : " navOnly"
                }`}
              >
                <div className="chHeaderContainer">
                  <span className="logoContainer">
                    <a className="chMmtLogo" href="/">
                      <img
                        src="images/logo/seyLogo.png"
                        alt="Holiday Seychelles"
                      />
                    </a>
                  </span>

                  <BannerTabNav
                    tabs={TabsArr}
                    selected={this.state.selected}
                    setSelectedTab={this.setSelectedTabOptions}
                  ></BannerTabNav>

                  <ul className="userSection pushRight">
                    {/* {this.state.customerEmailForData == 'abc' ? (<li className="hsFlex_Bann hsCenter hsMyWallet" onClick={this.openModal}><span className="landingSprite walletBlanceIcon appendRight10">&nbsp;</span><span></span>
                                        </li>
                                        ) : (

                                            <Link to="/Wallet" target="_blank">
                                                <li className="hsFlex_Bann hsCenter hsMyWallet"><span className="landingSprite walletBlanceIcon appendRight10">&nbsp;</span><span></span>
                                                </li>
                                            </Link>
                                        )} */}
                    {this.state.customerEmailForData == "abc" ? (
                      <li
                        className="hsFlex_Bann hsCenter font10 hsRelative hsUser"
                        onClick={this.openModal}
                      >
                        <div className="hsFlex_Bann column flexOne latoBold">
                          <p className="appendBottom3_Header">
                            {" "}
                            <img
                              src="images/icon/favicon.ico"
                              className="loginIcon"
                            />{" "}
                            Login or Create Account
                          </p>
                        </div>
                      </li>
                    ) : (
                      <li
                        className="hs-LoginFlex hsCenter font10 hsRelative hsUser userLoggedIn"
                        onClick={this.OpenProfilelinkAgain}
                      >
                        {this.state.customerDetails.profile_pic == "" ? (
                          <span className="userNameIcon hs-white hs-LoginFlex perfectCenter latoBlack appendRight10">
                            <span>
                              {this.state.customerDetails?.first_name?.charAt(0)}
                            </span>
                          </span>
                         ) : (
                          // <span className="userNameImage  appendRight10">
                          //   <img
                          //     style={{ width: "100%", borderRadius: "100%" }}
                          //     src={this.state.customerDetails.profile_pic}
                          //   />
                          // </span>
                          <span className="userNameIcon hs-white hs-LoginFlex perfectCenter latoBlack appendRight10">
                          <span>
                            {this.state.customerDetails?.first_name?.charAt(0)}
                          </span>
                        </span>
                        )} 

                        <div className="hs-LoginFlex column userNameText  latoBold">
                          <p
                            data-cy="loggedInUser"
                            className="appendBottom3 truncate customerName"
                          >
                            Hey {this.state.customerDetails.first_name}
                          </p>
                        </div>
                        {this.state.profileNewAgain && (
                          <div className="userDropdown fadeInDown hs-LoginFlex column dropbanner">
                            <a
                              className="userItems hs-LoginFlex"
                              href="/PlanTrips"
                              target="_blank"
                            >
                              <span className="landingSprite userItemMyTrips appendRight20"></span>
                              <div>
                                <p className="font16 latoBold blackText appendBottom5">
                                  My Trips
                                </p>
                                <p className="font12 lineHeight16 blackText">
                                  See booking details, planned your own trip,
                                  Cancel Booking &amp; more.
                                </p>
                              </div>
                            </a>
                            {/* <a
                              className="userItems hs-LoginFlex"
                              href="/ExpreriancePlanTrips"
                              target="_blank"
                            >
                              <span className="landingSprite userItemMyTrips appendRight20"></span>
                              <div>
                                <p className="font16 latoBold blackText appendBottom5">
                                  My Expreriance Trips
                                </p>
                                <p className="font12 lineHeight16 blackText">
                                  See booking details, expreriance planned your own trip,
                                  Cancel Booking &amp; more.
                                </p>
                              </div>
                            </a> */}

                            {/* <a className="userItems hs-LoginFlex" href="/Wallet" target="_blank">
                                                            <span className="landingSprite userItemMyWallet appendRight20"></span>
                                                            <div>
                                                                <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">My Wallet
                                                <span className="myWalletTag font12 hs-white appendLeft5 latoBold"> ₹ 0</span>
                                                                </p>

                                                                <p className="font12 lineHeight16 blackText">Use your wallet money to avail even greater discounts</p>
                                                            </div></a> */}

                            <a
                              className="userItems hs-LoginFlex"
                              href="/Profile"
                            >
                              <span className="landingSprite userItemMyProfile appendRight20"></span>
                              <div>
                                <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">
                                  My Profile
                                </p>
                                <p className="font12 lineHeight16 blackText">
                                  Manage your profile, login details and
                                  password
                                </p>
                              </div>
                            </a>
                            <a
                              className="userItems hs-LoginFlex appendBottom10"
                              onClick={this.logoutChange}
                            >
                              <span className="landingSprite userItemLogout appendRight20"></span>
                              <div>
                                <p className="font16 latoBold blackText appendBottom5 hs-LoginFlex hsCenter">
                                  Logout
                                </p>
                              </div>
                            </a>
                          </div>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <Banner isSelected={this.state.selected} />
            <Link to="/enterDetails">
              <button id="mobileSearchBtn">Search</button>
            </Link>
          </div>
        </div>

        {this.state.OfferData == null ? (
          <>
            {" "}
            <SkeletonHome />{" "}
          </>
        ) : (
          <>
            <Superoffers OfferData={this.state.OfferData} logindata={this.state.customerDetails?.email}/>
            <Event />
            <Footer
              showNews={"News"}
              tabs={TabsArr}
              selected={this.state.selected}
              setSelectedTab={this.setSelectedTabOptions}
            />{" "}
          </>
        )}

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={this.customStyles}
          contentLabel="Example Modal"
          className="LoginModal"
        >
          <div className={this.state.ChangeLoginScreenClass}>
            <div className="form-container sign-up-container">
              <form className="FormCon">
                <h1>Create Account</h1>
                <SignUp
                  country_code={this.state.country_code}
                  currency={this.state.currency}
                  ip_address={this.state.ip_address}
                  country={this.state.country}
                />
              </form>
            </div>
            {this.state.facebookLogin && (
              <FacebookLogin
                // appId="426993597895758"
                appId="1194539465165185"
                autoLoad={true}
                fields="first_name, last_name, email, picture, birthday,gender"
                scope="public_profile, email, user_birthday,user_gender"
                returnScopes={true}
                callback={this.responseFacebook}
              />
            )}
            {/* {this.state.GoogleLoginButton &&
                  
                        } */}
            <div className="form-container sign-in-container">
              <form className="FormCon">
                <h1>LOG IN</h1>
                {this.state.errorPassword && (
                  <p className="error"> Email or Password is not correct !</p>
                )}
                <input
                  type="email"
                  placeholder="Email"
                  value={
                    this.state.LoginEmail
                      ? this.state.LoginEmail
                      :""
                  }
                  onChange={this.ChangeLoginEmail}
                />
                 {this.state.EmailError && (
                    <p className="error">
                      {" "}
                      Please enter a valid Email ID!
                    </p>
                  )}
                <input
                  type="password"
                  placeholder="Password"
                  value={this.state.LoginPassword}
                  onChange={this.ChangeLoginPassword}
                />
                {this.state.PasswordLoginError && (
                    <p className="error">
                      {" "}
                      The password that you've entered is incorrect
                    </p>
                  )}
                
                <a onClick={this.openSecondModal}>Forgot your password?</a>
                <button onClick={this.LoginConfirm}>LOG IN</button>
                <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                  <div
                    className="SocialmakeFlex fbLoginBtn flexOne"
                    onClick={this.LoginFaceBook}
                  >
                    <span className=" popupSprite popupFbIcon"></span>
                    <span
                      className="font14 hs-white latoBold latoBold"
                      style={{ textTransform: "uppercase" }}
                    >
                      Login with Facebook
                    </span>
                  </div>
                </div>
                <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                  <div className="SocialmakeFlex googleLoginBtn flexOne">
                    <span style={{ width: "100%" }}>
                      {" "}
                      <GoogleLogin
                        clientId="924181911314-6ml7tmqad8dkh9ugosligld4b9kkremq.apps.googleusercontent.com"
                        buttonText="Login with Google"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>Welcome Back!</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <button className="ghost" onClick={this.ChangeLoginReverse}>
                    LOG IN
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <p>Enter your personal details and start journey with us</p>
                  <button className="ghost" onClick={this.ChangeLoginScreen}>
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.modalIsSecondOpen}
          onRequestClose={this.closeSecondModal}
          style={this.customStyles}
          contentLabel="Example Modal"
        >
          <div className={this.state.ChangeLoginScreenClass}>
            <div className="form-container sign-in-container">
              <form className="FormCon">
                <h1>Fogot Password</h1>
                {this.state.errorForgotEmail && (
                  <p className="error"> Email is not correct !</p>
                )}
                <input
                  type="email"
                  placeholder="Email"
                  value={this.state.ForgotEmail}
                  onChange={this.ChangeForgotEmail}
                />
              {this.state.ForgotEmailError && (
                  <p className="error"> Email is not valid !</p>
                )}
                <button onClick={this.ForgotConfirm}>Submit</button>
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-right">
                  <h1>Welcome Back!</h1>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <button className="ghost" onClick={this.modalIsOpen}>
                    LOG IN
                  </button>
                </div>
                {/* <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <p>Enter your personal details and start journey with us</p>
                  <button className="ghost" onClick={this.ChangeLoginScreen}>
                    Sign Up
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withCookies(Header);
