import React, { useState, useEffect } from "react";
import "./HotelList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Base64 } from "js-base64";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactHtmlParser from "react-html-parser";

function HotelList(props) {
  const [ImageChanger, setImageChanger] = useState();
  const [HotelData, setHotelData] = useState(props.details);
  const [gallery, setGallery] = useState(props.gallery);
  const [services, setServices] = useState(props.services);
  const [juniper, setJuniper] = useState(props.juniper);
  const [hotellink, setHotellink] = useState(props.hotellink);
  const [searchData, setSearchData] = useState(props.searchData);
  const [hsPortal, setHsPortal] = useState(props.hsPortal);
  const [match, setMatch] = useState(props.match);
  const [Offer, setOffer] = useState(props.special_off);
  const [SupplierUrl, setSupplierUrl] = useState(props.SupplierUrl);
  const [roomCount, setRoomCount] = useState();

  const onMouseOutFun = (image) => {
    setImageChanger(image);
  };
  const onMouseOverFun = (image) => {
    setImageChanger(image);
  };

  useEffect(() => {
    const Search = props.searchData;
    const searching = JSON.parse(Search);

    const roomC = searching.guestCombination.reduce(
      (a, v) => a + v.roomCounter,
      0
    );

    setRoomCount(roomC);
  }, []);

  const getHotellinkMeal = () => {
    const data = Object.entries(hotellink.forMeal);
    const rateId = data.filter(([key, value]) => value == hotellink.amount);

    const meal = hotellink.rateMeals.filter(
      (item, key) => item != null && item.rate_plan_id == rateId[0][0]
    );

    if (meal.length > 0) {
      return (
        <div className="priceDetailsTop">
          <p className="font12 latoBold appendBottom3 darkText">
            included in this price
          </p>
          <ul className="includes">
            <li className="greenText">
              <span className="includeIcon">
                <span className="spriteNew singleGreenTickIcon"></span>
              </span>
              <span className="includes__text">
                {(meal[0].meal_id == 1 && "Breakfast") ||
                  (meal[0].meal_id == 2 && "Lunch") ||
                  (meal[0].meal_id == 3 && "Dinner") ||
                  (meal[0].meal_id == 4 && "All Inclusive")}
              </span>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="priceDetailsTop">
          <p className="font12 latoBold appendBottom3 darkText">
            no meals included in this price
          </p>
        </div>
      );
    }
  };

  const getHsPortalMeal = () => {
    const data = Object.entries(hsPortal.forMeal);
    const rateId = data.filter(([key, value]) => value == hsPortal.amount);

    const meal = hsPortal.rateMeals.filter(
      (item, key) => item != null && item.rate_plan_id == rateId[0][0]
    );

    if (meal.length > 0) {
      return (
        <div className="priceDetailsTop">
          <p className="font12 latoBold appendBottom3 darkText">
            included in this price
          </p>
          <ul className="includes">
            <li className="greenText">
              <span className="includeIcon">
                <span className="spriteNew singleGreenTickIcon"></span>
              </span>
              <span className="includes__text">
                {(meal[0].meal_id == 1 && "Breakfast") ||
                  (meal[0].meal_id == 2 && "Lunch") ||
                  (meal[0].meal_id == 3 && "Dinner") ||
                  (meal[0].meal_id == 4 && "All Inclusive")}
              </span>
            </li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="priceDetailsTop">
          <p className="font10 latoBold appendBottom3 darkText">
            no meals included in this price
          </p>
        </div>
      );
    }
  };

  const getAmountHotelLink = (data) => {
    const data12 = Object.entries(data.forMeal);
    const rateId = data12.filter(([key, value]) => value == data.amount);

    

    const Offer1 = data.specialOffer.filter(
      (item, key) =>
        item != "no_offer" && item.roomSpecial.rate_plan_id == rateId[0][0]
    );

    if (Offer1.length > 0) {
      let result = [];

      Offer1.map((i, j) => {
        
        if (i.combineoffer == "nocombine") {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data.amount * parseInt(i.specialdata.pay_stay_length) -
              data.amount * parseInt(i.specialdata.pay_stay_value);
             
            const disA =
              (dis / (data.amount * parseInt(i.specialdata.pay_stay_length))) *
              100;
           
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data.amount - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        } else {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data.amount * parseInt(i.specialdata.pay_stay_length) -
              data.amount * parseInt(i.specialdata.pay_stay_value);
            const disA =
              (dis / (data.amount * parseInt(i.specialdata.pay_stay_length))) *
              100;
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data.amount - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        }
      });

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount =
        data.amount - (Math.round(Amount * data.amount) / 100).toFixed(2);

      return (
        <>
          <div className="containerListing"> 
          <p
            className="grayText font16 lineThrough appendBottom5"
            id="hlistpg_hotel_cut_price"
          >
           <span>€</span>{(data.amount).toFixed(2)}
          </p>
          {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
          <p
            className="latoBlack font16 blackText appendBottom5 appendLeft5"
            id="hlistpg_hotel_shown_price"
          >
            <span>€</span>{balanceAmount.toFixed(2)}
          </p>
          </div>
          <p class="hrtlCenter appendBottom10 directionRtl">
            <span class="redText font12">
              <span>Save </span>
              <span class="latoBold" id="hlistpg_hotel_saved_price">
              <span>€</span>{(Math.round(Amount * data.amount) / 100).toFixed(2)}
              </span>
            </span>
            <span class="spriteNew discountIcon relative appendLeft5">
              <span
                class="latoBold font11 whiteText discountText"
                id="hlistpg_hotel_saved_price_percentage"
                style={{
                  lineHeight: "15px",
                  paddingRight: "5px",
                }}
              >
                {parseInt(Amount)}%
              </span>
            </span>
          </p>
        </>
      );
    } else {
      return (
        <>
        {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
        <p
          className="latoBlack font16 blackText appendBottom5"
          id="hlistpg_hotel_shown_price"
        >
          <span>€</span>{(data.amount).toFixed(2)}
        </p>
        </>
      );
    }
  };

  const showSpecialOffer = (data) => {
    const data12 = Object.entries(data.forMeal);
    const rateId = data12.filter(([key, value]) => value == data.amount);

   
    const Offer1 = data.specialOffer.filter(
      (item, key) =>
        item != "no_offer" && item.roomSpecial.rate_plan_id == rateId[0][0]
    );

    return (
      Offer1.length > 0 && (
        <div class="title__placeHolder font12 pc__bottomBox">
          <div class="persuasion ">
            <div class="persuasion__item pc__cashbackDeal">
              <span class="sprite infoIconGreen"></span>
              <span>
                Special Offer -{" "}
                {Offer1.map((i, j) => {
                  if (i.combineoffer == "nocombine") {
                    return Offer1[j].name;
                  } else {
                    return Offer1[j].name;
                  }
                }).join(",")}
              </span>
            </div>
          </div>
        </div>
      )
    );
  };

  const getAmountHsPortal = (data) => {

    const data12 = Object.entries(data.forMeal);
    const rateId = data12.filter(([key, value]) => value == data.amount);

    const Offer1 = data.specialOffer.filter(
      (item, key) =>
        item != "no_offer" && item.roomSpecial.rate_plan_id == rateId[0][0]
    );

    if (Offer1.length > 0) {
      let result = [];

      Offer1.map((i, j) => {
     
        if (i.combineoffer == "nocombine") {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data.amount * parseInt(i.specialdata.pay_stay_length) -
              data.amount * parseInt(i.specialdata.pay_stay_value);
         
            const disA =
              (dis / (data.amount * parseInt(i.specialdata.pay_stay_length))) *
              100;
          
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data.amount - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        } else {
          if (i.specialdata.spl_offer_type_id == 1) {
            const dis =
              data.amount * parseInt(i.specialdata.pay_stay_length) -
              data.amount * parseInt(i.specialdata.pay_stay_value);
            const disA =
              (dis / (data.amount * parseInt(i.specialdata.pay_stay_length))) *
              100;
            result.push(disA);
          } else {
            if (i.specialdata.discount_in == 2) {
              const disA = i.specialdata.rate_discount;
              result.push(+disA);
            } else {
              const disA = data.amount - i.specialdata.rate_discount;
              result.push(+disA);
            }
          }
        }
      });

      const Amount = result.reduce((a, v) => a + v, 0);

      const balanceAmount =
        data.amount - (Math.round(Amount * data.amount) / 100).toFixed(2);

      return (
        <>
          <div className="containerListing">
          <p
            className="grayText font16 lineThrough appendBottom5"
            id="hlistpg_hotel_cut_price"
          >
           <span>€</span>{(data.amount).toFixed(2)}
          </p>
          {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
          <p
            className="latoBlack font16 blackText appendBottom5 appendLeft5"
            id="hlistpg_hotel_shown_price"
          >
           <span>€</span>{balanceAmount.toFixed(2)}
          </p>
          </div>
          <p class="hrtlCenter appendBottom10 directionRtl">
            <span class="redText font12">
              <span>Save </span>
              <span class="latoBold" id="hlistpg_hotel_saved_price">
              <span>€</span>{(Math.round(Amount * data.amount) / 100).toFixed(2)}
              </span>
            </span>
            <span class="spriteNew discountIcon relative appendLeft5">
              <span
                class="latoBold font11 whiteText discountText"
                id="hlistpg_hotel_saved_price_percentage"
                style={{
                  lineHeight: "15px",
                  paddingRight: "5px",
                }}
              >
                {parseInt(Amount)}%
              </span>
            </span>
          </p>
        </>
      );
    } else {
      return (
        <>
        {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
        <p
          className="latoBlack font16 blackText appendBottom5"
          id="hlistpg_hotel_shown_price"
        >
         <span>€</span>{(data.amount).toFixed(2)}
        </p>
        </>
      );
    }
  };


  const starsCount = () => {

    if (HotelData.star_category_id == 1) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelData.star_category_id == 2) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelData.star_category_id == 3) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelData.star_category_id == 4) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelData.star_category_id == 5) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ff904a"></FontAwesomeIcon>
        </>
      );
    }
  };
  
  return (
    <>
      <div
        className={`Container-Tile ${match == "matchkey" ? "matchback" : ""}`}
      >
        <a
          href={
            "/hotelDetails?hotelid=" +
            Base64.btoa(HotelData.id) +
            "&query=" +
            encodeURIComponent(searchData)
          }
        >
          <>
            <div className="Image-Section">
              {HotelData.property_source == 1 ? (
                <>
                  {gallery != null ? (
                    gallery
                      .slice(0, 1)
                      .map((item, key) => (
                        <LazyLoadImage
                          src={ImageChanger == null ? item.image : ImageChanger}
                          className="gallery__img"
                          placeholderSrc={
                            process.env.PUBLIC_URL + "images/placeholder.png"
                          }
                          key={key}
                        />
                      ))
                  ) : (
                    <LazyLoadImage src={"images/placeholder.png"} />
                  )}
                  {/* <div className="SmallImage">
                    {gallery != null ? (
                      gallery
                        .slice(0, 4)
                        .map((item, key) => (
                          <LazyLoadImage
                            className="imgThumb"
                            src={
                              item.image != null
                                ? item.image
                                : "images/placeholder.png"
                            }
                            onMouseOver={(e) =>
                              onMouseOverFun(e.currentTarget.src)
                            }
                            onMouseOut={(e) =>
                              onMouseOutFun(e.currentTarget.src)
                            }
                            key={key}
                          />
                        ))
                    ) : (
                      <></>
                    )}
                  </div> */}
                </>
              ) : (
                <>
                  {gallery != null ? (
                    gallery
                      .slice(0, 1)
                      .map((item, key) => (
                        <LazyLoadImage
                          src={
                            ImageChanger == null
                              ? SupplierUrl+"/uploads/property_gallery_main/" +
                                item.image
                              : ImageChanger
                          }
                          className="gallery__img"
                        />
                      ))
                  ) : (
                    <LazyLoadImage src={"images/placeholder.png"} />
                  )}
                  {/* <div className="SmallImage">
                    {gallery != null ? (
                      gallery
                        .slice(0, 4)
                        .map((item, key) => (
                          <LazyLoadImage
                            className="imgThumb"
                            src={
                              "https://supplier.holidays-seychelles.com/uploads/property_gallery_main/" +
                              item.image
                            }
                            onMouseOver={(e) =>
                              onMouseOverFun(e.currentTarget.src)
                            }
                            onMouseOut={(e) =>
                              onMouseOutFun(e.currentTarget.src)
                            }
                          />
                        ))
                    ) : (
                      <>
                        <LazyLoadImage
                          className="imgThumb"
                          src={"images/placeholder.png"}
                          onMouseOver={(e) =>
                            onMouseOverFun(e.currentTarget.src)
                          }
                          onMouseOut={(e) => onMouseOutFun(e.currentTarget.src)}
                        />
                        <LazyLoadImage
                          className="imgThumb"
                          src={"images/placeholder.png"}
                          onMouseOver={(e) =>
                            onMouseOverFun(e.currentTarget.src)
                          }
                          onMouseOut={(e) => onMouseOutFun(e.currentTarget.src)}
                        />
                        <LazyLoadImage
                          className="imgThumb"
                          src={"images/placeholder.png"}
                          onMouseOver={(e) =>
                            onMouseOverFun(e.currentTarget.src)
                          }
                          onMouseOut={(e) => onMouseOutFun(e.currentTarget.src)}
                        />
                        <LazyLoadImage
                          className="imgThumb"
                          src={"images/placeholder.png"}
                          onMouseOver={(e) =>
                            onMouseOverFun(e.currentTarget.src)
                          }
                          onMouseOut={(e) => onMouseOutFun(e.currentTarget.src)}
                        />
                      </>
                    )}
                  </div> */}
                </>
              )}
            </div>
            <div className="Text-Section">
              <div className="Text-Contents">
                {/* <img src={Logo} className="logo" /> */}
                <div className="Text-Ratings">
                  {/* <h3 className="text-Color"></h3><p></p> */}
                  <p
                    className="latoBlack font16 blackText appendBottom12"
                    style={{ marginRight: 10 }}
                  >
                    <span>{HotelData.property_name}</span>
                  </p>
                 
                </div>
                <span className="rating_blank"  style={{fontSize: '10px'}}>{starsCount()}</span>
                {HotelData.property_source == 1 ? (
                  <>
                    {juniper.ActualOfferName == "" ? (
                      <></>
                    ) : (
                      <p className="SpecialOffer">{juniper.ActualOfferName}</p>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <div className="Small-Text">
                  <p className="address" style={{ color: "#65cbf3" }}>
                  {`${HotelData.address_line_1}, ${HotelData.city}, ${HotelData.island}`}
                  </p>

                  {/* <p className="highlight">Couple Friendly</p> */}
                 
                  {HotelData.property_source == 1 ? (
                    <p
                      style={{
                        fontSize: '12px', color: '#000',
                        // marginTop: 8,
                        textAlign: "justify",
                        marginRight: 15,
                      }}
                    >{`${juniper[0].description.substring(0, 150)}...`}</p>
                  ) : (
                    <p
                      style={{
                        fontSize: '13px', color: '#000',
                        marginTop: 8,
                        textAlign: "justify",
                        marginRight: 15,
                      }}
                    >
                      {ReactHtmlParser(
                        `${HotelData.property_description.substring(0, 150)}...`
                      )}
                    </p>
                  )}
                </div>
                <div className="Extras">
                    {services != null ? (
                      services
                        .slice(0, 5)
                        .map((item, key) => (
                          <p className="hGXiWF">
                            {" "}
                            {item.service_and_facility} 
                          </p>
                        ))
                    ) : (
                      <></>
                    )}
                     {services != null && services.length > 5&&(<p className="hGXiWF">{`${((services.length)-5)}...more`}</p>)}
                    {}
                  </div>
              </div>
              {/* <div
                className="noShrink appendLeft20"
                itemprop="aggregateRating"
                itemscope=""
                itemtype="http://schema.org/AggregateRating"
              >
                <div
                  className="makeFlex right appendBottom5 hrtlCenter whiteText"
                  style={{ marginTop: "0px" }}
                >
                  <span className="makeFlex hrtlCenter rating taRatingTooltipCont greenBgTA">
                    <span className="spriteNew taratingIconNew"></span>
                    <span className="rating font12 latoBlack greenBgTA  appendLeft3">
                      <span itemprop="ratingValue">3.5</span>
                      <span className="latoRegular font10">/5</span>
                    </span>
                  </span>
                </div>
                <p className="font11 capText darkText">
                  <span
                    className="blackText latoBold"
                    id="hlistpg_hotel_reviews_count"
                    itemprop="reviewCount"
                  ></span>{" "}
                  Ratings
                </p>
              </div> */}
              <div className="priceDetails textRight">
              {/* <span className="rating_blank" style={{marginTop: 20, marginRight: 20}}>{starsCount()}</span> */}
                {HotelData.property_source == 1 && (
                  <div className="priceDetailsTop">
                    <p className="font12 latoBold appendBottom3 darkText">
                      included in this price
                    </p>
                    <ul className="includes">
                      <li className="greenText">
                        <span className="includeIcon">
                          <span className="spriteNew singleGreenTickIcon"></span>
                        </span>
                        <span className="includes__text">
                          {juniper.HotelOptions.HotelOption.Board}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}

                {HotelData.property_source == 2 && getHotellinkMeal()}
                {HotelData.property_source == 0 && getHsPortalMeal()}
                <div>
                  <div
                    className="padding20 makeFlex column"
                    style={{ marginTop: "0px" }}
                  >
                    {roomCount > 1 ? (
                      <p className="font12 grayText appendBottom5">
                       Per Night for {roomCount} Rooms From{" "} 
                      </p>
                    ) : (
                      <p className="font12 grayText appendBottom5">
                      Per Night From{" "}
                      </p>
                    )}
                    {HotelData.property_source == 1 ? (
                      <>
                        {juniper.HotelOfferAmount == "" ? (
                          <p
                            className="latoBlack font16 blackText appendBottom5"
                            id="hlistpg_hotel_shown_price"
                          >
                           <span>€</span>
                            {(
                              Math.round(juniper.NewAmount * 100) / 100
                            ).toFixed(2)}
                          </p>
                        ) : (
                          <>
                            {" "}
                            {juniper.PercentageAm == 0 ? (
                              <>
                               {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
                              <p
                                className="latoBlack font16 blackText appendBottom5"
                                id="hlistpg_hotel_shown_price"
                              >
                               <span>€</span>
                                {(
                                  Math.round(juniper.Amount * 100) / 100
                                ).toFixed(2)}
                              </p>
                              </>
                            ) : (
                              <>
                                {" "}
                                <div className="containerListing">
                                <p
                                  className="grayText font16 lineThrough appendBottom5"
                                  id="hlistpg_hotel_cut_price"
                                >
                                 <span>€</span>
                                  {(
                                    Math.round(juniper.Amount * 100) / 100
                                  ).toFixed(2)}
                                </p>
                                {/* <p><span className="font-weight-bold text-lowercase from-price-text">From </span></p> */}
                                <p
                                  className="latoBlack font16 blackText appendBottom5 appendLeft5"
                                  id="hlistpg_hotel_shown_price"
                                >
                                <span>€</span>
                                  {(
                                    Math.round(juniper.HotelOfferAmount * 100) /
                                    100
                                  ).toFixed(2)}
                                </p>
                                </div>
                                <p class="hrtlCenter appendBottom10 directionRtl">
                                  <span class="redText font12">
                                    <span>Save </span>
                                    <span
                                      class="latoBold"
                                      id="hlistpg_hotel_saved_price"
                                    >
                                     <span>€</span>
                                      {(
                                        Math.round(
                                          juniper.HotelOfferAmNew * 100
                                        ) / 100
                                      ).toFixed(2)}
                                    </span>
                                  </span>
                                  <span class="spriteNew discountIcon relative appendLeft5">
                                    <span
                                      class="latoBold font11 whiteText discountText"
                                      id="hlistpg_hotel_saved_price_percentage"
                                      style={{
                                        lineHeight: "15px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      {juniper.PercentageAm}%
                                    </span>
                                  </span>
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {HotelData.property_source == 2 ? (
                          <>
                            {hotellink == "" ? (
                              <></>
                            ) : (
                              <>
                                {getAmountHotelLink(hotellink)}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {hsPortal == "" ? (
                              <></>
                            ) : (
                              <>{getAmountHsPortal(hsPortal)}</>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <p className="appendBottom5"></p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </a>
      </div>
      {HotelData.property_source == 1 && juniper.HotelOfferName != "" ? (
        <div class="title__placeHolder font12 pc__bottomBox">
          <div class="persuasion ">
            <div class="persuasion__item pc__cashbackDeal">
              <span class="sprite infoIconGreen"></span>
              <span>Special Offer - {juniper.HotelOfferName}</span>
            </div>
          </div>
        </div>
      ) : (
        <>   {HotelData.property_source == 2 ? (
          <>
            {hotellink == "" ? (
              <></>
            ) : (
              <>
                {showSpecialOffer(hotellink)}
              </>
            )}
          </>
        ) : (
          <>
            {hsPortal == undefined ? (
              <></>
            ) : (
              <>{showSpecialOffer(hsPortal)}</>
            )}
          </>
        )}</>
      )}
    </>
  );
}

export default HotelList;
